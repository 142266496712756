import { useEffect, useState } from 'react';

import { getCpInfo, getCpImageInfo } from '@/api/couple/cpInfo';
import { useUserStore } from '@/store/user';
import { CpImageType, CpInfoType } from '@/types/cpType';

import ImageInfo from '../../app/cp/[userId]/(image)/i/[imageId]/imageInfo';

function CpImageInfoPage({
    userId,
    imageId,
    onClose
}: {
    userId: string;
    imageId: string;
    onClose: () => void;
}) {
    const userInfo = useUserStore.use.userInfo();
    const [cpRes, setCpRes] = useState<CpInfoType>({} as CpInfoType);
    const [imgRes, setImgRes] = useState<CpImageType>({} as CpImageType);

    useEffect(() => {
        const fetchData = async () => {
            const res = await getCpInfo(userId);
            if (res.status === 200) {
                setCpRes(res.data);
            }
            const resImg = await getCpImageInfo({
                userId: userId,
                imageId: imageId,
                serverAccess: userInfo.access_token
            });
            if (resImg.status === 200) {
                setImgRes(resImg.data);
            }
        };
        fetchData();
    }, [imageId, userId, userInfo.access_token]);

    return (
        <>
            {cpRes.account && imgRes.url && (
                <ImageInfo onClose={onClose} userInfo={cpRes} imageInfo={imgRes} />
            )}
        </>
    );
}

export default CpImageInfoPage;
