import Image from 'next/image';

import MediaWithAuthHeader from '@/components/mediaWithAuthHeader/mediaWithAuthHeader';
import { useUserStore } from '@/store/user';
import { ResMessage } from '@/types/chat';

import DiamondIcon from './img/diamond.png';
import PlayIcon from './img/play.svg';

import style from './contentVideo.module.scss';

function ContentVideo({
    message,
    mode = 'user',
    switchDetail
}: {
    message: ResMessage;
    mode: 'cp' | 'user';
    switchDetail: (message: ResMessage) => void;
}) {
    const status = {
        0: mode === 'user' ? '影片已傳送！' : '對方已傳送影片！',
        1: '已過期',
        2: mode === 'user' ? '影片已傳送！' : '對方已傳送影片！',
        3: mode === 'user' ? '對方已解鎖！' : '已解鎖！'
    };

    const showDetail = () => {
        if (message.status === 1) return;
        switchDetail(message);
    };
    const userInfo = useUserStore.use.userInfo();

    return (
        <div className={style.videoBox} onClick={showDetail}>
            <div
                className={style.videoSize}
                style={{
                    aspectRatio: `${message.msg.width || message.msg.detail?.width} / ${
                        message.msg.height || message.msg.detail?.height
                    }`
                }}
            >
                <div
                    className={style.fit}
                    style={{
                        width: message.msg.width || message.msg.detail?.width,
                        height: message.msg.height || message.msg.detail?.height
                    }}
                >
                    {message.msg.detail &&
                        (message.from === userInfo.user_id || !message.msg.is_lock ? (
                            <MediaWithAuthHeader
                                className={style.mediaImg}
                                src={message.msg.detail.cover}
                            />
                        ) : (
                            <Image
                                className={style.mediaImg}
                                src={message.msg.detail.cover}
                                width={message.msg.width || message.msg.detail.width}
                                height={message.msg.height || message.msg.detail.height}
                                alt="img"
                            />
                        ))}
                    {message.msg.type === 'video' && (
                        <div className={style.play}>
                            <PlayIcon />
                        </div>
                    )}
                </div>
            </div>
            <div className={style.noted}>
                <p>{status[message.status as 0 | 1 | 2 | 3]}</p>
                {message.msg.diamond > 0 && message.msg.is_lock && (
                    <p className={style.diamond}>
                        <Image src={DiamondIcon} width={12} height={12} alt="diamond" />
                        {message.msg.diamond}
                    </p>
                )}
            </div>
        </div>
    );
}

export default ContentVideo;
