import MediaWithAuthHeader from '@/components/mediaWithAuthHeader/mediaWithAuthHeader';
import { useChatStore } from '@/store/chat';

import CloseIcon from './img/close.svg';

import style from './chatReply.module.scss';

function ChatReply({ replyData }: { replyData: { img: string; description: string } }) {
    const resetReply = useChatStore.use.resetReplyData();

    return (
        <div className={style.chatReply}>
            <div className={style.replyContent}>
                <div className={style.img}>
                    <MediaWithAuthHeader src={replyData.img} alt="whywhy" />
                </div>
                <div className={style.content}>{replyData.description}</div>
            </div>
            <div className={style.close} onClick={resetReply}>
                <CloseIcon />
            </div>
        </div>
    );
}

export default ChatReply;
