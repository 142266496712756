import CpAvatar from '@/components/cp/cpAvatar/cpAvatar';
import { timestampToHHMM } from '@/lib/timeHandle';
import { ChatInfo, ResMessage } from '@/types/chat';

import ContentGift from './contentMedia/contentGift';
import ContentImage from './contentMedia/contentImage';
import ContentText from './contentMedia/contentText';
import ContentVideo from './contentMedia/contentVideo';

import style from './cpBubble.module.scss';

function CpBubble({
    chatInfo,
    message,
    switchDetail
}: {
    chatInfo: ChatInfo;
    message: ResMessage;
    switchDetail: (message: ResMessage) => void;
}) {
    const owner = chatInfo.participants[0].user_id === message.from ? 0 : 1;
    return (
        <div className={style.cpBox}>
            <CpAvatar
                width={30}
                height={30}
                className={style.cpAvatar}
                src={chatInfo.participants[owner].avatar}
                tag={chatInfo.participants[owner].label}
            />
            {message.msg.type === 'text' && <ContentText role="cp" message={message} />}
            {message.msg.type === 'gift' && <ContentGift message={message} />}
            {message.msg.type === 'image' && (
                <ContentImage message={message} mode="cp" switchDetail={switchDetail} />
            )}
            {message.msg.type === 'video' && (
                <ContentVideo message={message} mode="cp" switchDetail={switchDetail} />
            )}
            <div className={style.date}>{timestampToHHMM(message.created_at)}</div>
        </div>
    );
}

export default CpBubble;
