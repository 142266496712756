'use client';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import React, { useState } from 'react';

import BaseButton from '@/components/baseButton/baseButton';
import { useWalletInfo } from '@/hooks/useWalletInfo';
import { useBaseStore } from '@/store/base';
import { useChatStore } from '@/store/chat';
import { useDepositStore } from '@/store/deposit';
import { useUserStore } from '@/store/user';

import SelectCard from './components/selectCard';
import IconCard from './img/card.svg';
import IconClose from './img/close.svg';
import Diamond from './img/diamond.png';

import style from './depositModal.module.scss';
function DiamondCount() {
    const { walletInfo } = useWalletInfo();
    return <>{walletInfo.user_diamond.toLocaleString()}</>;
}
function DepositModal() {
    const [selectedPlanId, setSelectedPlanId] = useState<number>(1);
    const [paymentMethod, setPaymentMethod] = useState('card-prime');
    const depositPopup = useDepositStore.use.depositPopup();
    const setDepositPopup = useDepositStore.use.setDepositPopup();
    const depositProducts = useDepositStore.use.depositProducts();
    const isLogin = useUserStore.use.isLogin();
    const setLoginPopup = useBaseStore.use.setLoginPopup();
    const switchChat = useChatStore.use.switchChat();
    const openChat = useChatStore.use.openChat();

    const router = useRouter();

    const goDepositDetail = () => {
        if (!isLogin) {
            setDepositPopup();
            setLoginPopup();
            return;
        }
        router.push(`/payment?planId=${selectedPlanId}&method=${paymentMethod}`);
        if (openChat) {
            switchChat();
        }
        setDepositPopup();
    };

    const selectedPlan = depositProducts.find(item => item.id === selectedPlanId);

    return (
        <>
            <SwipeableDrawer
                PaperProps={{
                    style: {
                        borderTopLeftRadius: '16px',
                        borderTopRightRadius: '16px'
                    }
                }}
                anchor="bottom"
                onClose={setDepositPopup}
                onOpen={setDepositPopup}
                open={depositPopup}
                swipeAreaWidth={20}
                disableSwipeToOpen
                transitionDuration={{ enter: 300, exit: 200 }}
            >
                <div className={style.depositModal}>
                    <header className={style.header}>
                        <IconClose className={style.close} onClick={setDepositPopup} />
                        儲值
                    </header>
                    <div className={style.content}>
                        <div className={style.headerInit}></div>
                        <div className={style.myDiamond}>
                            <Image src={Diamond} width={20} height={20} alt="myDiamond" />
                            <div className={style.diamondInfo}>
                                <div className={style.value}>
                                    {depositPopup && isLogin ? <DiamondCount /> : '0'}
                                </div>
                                <div className={style.label}>剩餘鑽石</div>
                            </div>
                        </div>
                        <div className={style.paymentMethod}>
                            <div className={style.title}>支付方式</div>
                            <div className={style.cards}>
                                <SelectCard
                                    selected
                                    className={style.card}
                                    onClick={() => setPaymentMethod('card-prime')}
                                >
                                    <IconCard />
                                    <div className={style.label}>信用卡</div>
                                </SelectCard>
                            </div>

                            <div className={style.tips}>
                                海外用戶提醒：國際交易手續費、台幣匯率將依您的刷卡銀行公告為準。
                            </div>
                        </div>
                        <div className={style.paymentPlan}>
                            <div className={style.title}>鑽石禮包</div>
                            <div className={style.cards}>
                                {depositProducts.map(item => {
                                    return (
                                        <SelectCard
                                            onClick={() => setSelectedPlanId(item.id)}
                                            selected={item.id === selectedPlanId}
                                            className={style.card}
                                            label={item.label}
                                            key={item.id}
                                        >
                                            <div className={style.planDiamond}>
                                                <Image
                                                    src={Diamond}
                                                    width={14}
                                                    height={14}
                                                    alt="myDiamond"
                                                />
                                                <div className={style.value}>
                                                    {(item.diamond + item.bonus).toLocaleString()}
                                                </div>
                                            </div>
                                            {item.bonus > 0 ? (
                                                <div className={style.label}>
                                                    {`含贈送 ${item.bonus} 鑽`}
                                                </div>
                                            ) : (
                                                <div className={style.label} style={{ opacity: 0 }}>
                                                    {item.bonus}
                                                </div>
                                            )}
                                            <div
                                                className={style.price}
                                            >{`$${item.amount.toLocaleString()}`}</div>
                                        </SelectCard>
                                    );
                                })}
                            </div>

                            <div className={style.tips}>
                                <div>
                                    警告：違規私下轉帳將導致您的帳號被永久封鎖。與來歷不明帳戶交易，亦可能導致您的金融帳戶被凍結的風險。為確保您的安全與利益，請勿進行此類交易！
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className={style.footer}>
                        <div
                            className={style.serviceLink}
                            onClick={() => $crisp.push(['do', 'chat:open'])}
                        >
                            大額優惠請洽客服
                        </div>
                        <BaseButton onClick={goDepositDetail} disabled={!selectedPlan}>{`支付 $${
                            selectedPlan?.amount.toLocaleString() || ''
                        }`}</BaseButton>
                    </footer>
                </div>
            </SwipeableDrawer>
        </>
    );
}

export default DepositModal;
