import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

export const getUuid = () => {
    if (!Cookies.get('uuid')) {
        const uuid = uuidv4();
        Cookies.set('uuid', uuid, { expires: 700 });
        return uuid;
    }
    return Cookies.get('uuid') as string;
};
