'use client';
import { getToken, onMessage } from 'firebase/messaging';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

import { updateFcmToken } from '@/api/notify/notify';
import { useBaseStore } from '@/store/base';
import { useNotifyStore } from '@/store/notify';
import { useUserStore } from '@/store/user';
import { getMessagingInstance } from '@/utils/fcm';
import { getUuid } from '@/utils/uuid';

function NotificationAPP() {
    const isLogin = useUserStore.use.isLogin();
    const setFcmModal = useNotifyStore.use.setFcmModal();

    useEffect(() => {
        async function requestNotificationPermission() {
            try {
                const registerServiceWorker = async () => {
                    if ('serviceWorker' in navigator) {
                        try {
                            const regSW =
                                useBaseStore.getState().envParams === 'prod'
                                    ? '/firebase-messaging-sw-prod.js'
                                    : '/firebase-messaging-sw-dev.js?v1';
                            const registration = await navigator.serviceWorker.register(regSW);
                            return registration;
                        } catch (err) {
                            console.error('Service Worker registration failed:', err);
                        }
                    }
                };
                const swRegistration = await registerServiceWorker();
                await Notification.requestPermission();

                const messaging = getMessagingInstance();

                const currentToken = await getToken(messaging, {
                    vapidKey:
                        useBaseStore.getState().envParams === 'prod'
                            ? process.env.NEXT_PUBLIC_FCM_VAPIDKEY_PROD
                            : process.env.NEXT_PUBLIC_FCM_VAPIDKEY_DEV,
                    serviceWorkerRegistration: swRegistration
                });
                if (currentToken) {
                    const fcmToken = Cookies.get('fct');
                    if (fcmToken !== currentToken) {
                        const uuid = getUuid();
                        await updateFcmToken(currentToken, uuid);
                        Cookies.set('fct', currentToken, { expires: 365 });
                    }
                }

                onMessage(messaging, payload => {
                    const data = payload?.data?.data;
                    if (!data) return;
                    const notificationData = JSON.parse(data);
                    setFcmModal(notificationData);
                });
            } catch (err) {
                // eslint-disable-next-line
                console.log('[NotificationApp] An error occurred while retrieving token. ', err);
            }
        }

        if (isLogin) requestNotificationPermission();
    }, [isLogin]);

    return null;
}

export default NotificationAPP;
