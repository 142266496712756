'use client';
import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';

import { useNotifyStore } from '@/store/notify';

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

function SnackMessage() {
    const snackMessageInfo = useNotifyStore.use.snackMessage();
    const setClose = useNotifyStore.use.setClose();
    const handleClose = () => {
        setClose();
    };

    return (
        <Snackbar
            sx={{
                '.MuiSnackbarContent-root': {
                    backgroundColor: snackMessageInfo.type === 'error' ? '#B91C1C' : '#737373',
                    color: '#FAFAFA'
                }
            }}
            anchorOrigin={{
                vertical: snackMessageInfo.vertical || 'bottom',
                horizontal: snackMessageInfo.horizontal || 'center'
            }}
            open={snackMessageInfo.isOpen}
            onClose={handleClose}
            message={snackMessageInfo.message}
            autoHideDuration={1500}
            key="snack"
            TransitionComponent={SlideTransition}
        />
    );
}

export default SnackMessage;
