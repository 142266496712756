import { useState, useEffect } from 'react';

import {
    getWalletInfo,
    type WalletInfo,
    getCpWalletSummary,
    type GetCpWalletSummaryResponse
} from '@/api/wallet/wallet';
import { formatDate } from '@/utils/timeConverter';

function useWalletInfo() {
    const [walletInfo, setWalletInfo] = useState<WalletInfo>({
        user_id: '',
        user_diamond: 0,
        cp_diamond: 0
    });

    useEffect(() => {
        const fetchWalletInfo = async () => {
            const res = await getWalletInfo();
            if (res.status === 200) {
                setWalletInfo(res.data);
            }
        };
        fetchWalletInfo();
    }, [setWalletInfo]);

    return { walletInfo };
}
/** 取得當月份的 cp收益 */
function useCpWalletSummary() {
    const [cpWalletSummary, setCpWalletSummary] = useState<GetCpWalletSummaryResponse>({
        total: 0,
        message: 0,
        message_image: 0,
        message_video: 0,
        creation_image: 0,
        creation_video: 0,
        creation_film: 0,
        gift: 0,
        voice_call: 0
    });

    useEffect(() => {
        const fetchCpWalletSummary = async () => {
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth();
            const firstDay = new Date(year, month, 1);
            const lastDay = new Date(year, month + 1, 0);

            const res = await getCpWalletSummary({
                start_date: formatDate(firstDay),
                end_date: formatDate(lastDay)
            });
            if (res.status === 200) {
                setCpWalletSummary(res.data);
            }
        };
        fetchCpWalletSummary();
    }, []);

    return { cpWalletSummary };
}

export { useWalletInfo, useCpWalletSummary };
