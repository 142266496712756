import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useState, ChangeEvent, useRef } from 'react';

import { uploadMedia } from '@/api/chat/chat';
import { uploadFileToS3 } from '@/api/common/common';
import DiamondDrawer from '@/components/diamondDrawer/diamondDrawer';
import FullScreenPopup from '@/components/fullScreenPopup/FullScreenPopup';
import Loading from '@/components/loading/Loading';
import MediaWithAuthHeader from '@/components/mediaWithAuthHeader/mediaWithAuthHeader';
import { sendMessage } from '@/lib/websocket';
import { useNotifyStore } from '@/store/notify';
import { ChatInfo } from '@/types/chat';
import { dataURLtoFile } from '@/utils/dataURLtoFile';
import { fetchVideoContent } from '@/utils/fetchVideoContent';

import PenIcon from './img/pen.svg';
import PhotoPickIcon from './img/photoPick.svg';
// import VoicePickIcon from './img/voicePick.svg';

import style from './mediaPopup.module.scss';

type UploadInfo = {
    detail: {
        width: number;
        height: number;
        duration: number;
    };
    cover: string;
    playUrl: string;
};
type MediaInfo = { file: File; type: 'image' | 'video' };

function Preview({
    showPreview,
    selectedVideo,
    selectedImage,
    back,
    chatInfo,
    type,
    closeAll,
    loading,
    mode,
    upload
}: {
    showPreview: boolean;
    selectedVideo: string;
    selectedImage: string;
    back: () => void;
    chatInfo: ChatInfo;
    type: 'image' | 'video' | 'audio';
    closeAll: () => void;
    loading: boolean;
    mode: 'user' | 'cp';
    upload: () => Promise<UploadInfo | undefined>;
}) {
    const [showDiamond, setShowDiamond] = useState(false);
    const [diamond, setDiamond] = useState({
        image: chatInfo.cp?.price.image.current || 200,
        video: chatInfo.cp?.price.video.current || 200,
        audio: chatInfo.cp?.price.audio.current || 200
    });
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const disabled = useRef(false);

    const send = async () => {
        if (disabled.current) return;
        disabled.current = true;
        const res = await upload();
        if (res) {
            const { playUrl, detail, cover } = res;
            if (type === 'video') {
                sendMessage({
                    type,
                    content: playUrl,
                    diamond: mode === 'cp' ? diamond.video : 0,
                    detail: {
                        ...detail,
                        cover
                    }
                });
            } else {
                sendMessage({
                    type,
                    cover: playUrl,
                    content: playUrl,
                    diamond: mode === 'cp' ? diamond.image : 0,
                    detail: imageSize
                });
            }
        }
        disabled.current = false;
        closeAll();
    };

    return (
        <FullScreenPopup isOpen={showPreview} back={back} hiddenClose send={send}>
            <div className={style.preview}>
                {loading && (
                    <div className={style.loadingMask}>
                        <div className={style.loading}>
                            <Loading />
                        </div>
                    </div>
                )}
                {type === 'image' && (
                    <MediaWithAuthHeader
                        src={selectedImage}
                        setImageSize={(size: { width: number; height: number }) =>
                            setImageSize(size)
                        }
                        className={style.imageLimit}
                    />
                )}
                {type === 'video' && (
                    <MediaWithAuthHeader
                        isVideo
                        src={selectedVideo}
                        setVideoSize={(size: { width: number; height: number }) =>
                            setImageSize(size)
                        }
                        className={style.videoLimit}
                    />
                )}
                {mode === 'cp' && (
                    <div className={style.bottom}>
                        <div className={style.btn} onClick={() => setShowDiamond(true)}>
                            <PenIcon />
                            更改 | {diamond[type].toLocaleString()} 鑽
                        </div>
                    </div>
                )}
            </div>
            <DiamondDrawer
                isOpen={showDiamond}
                close={() => setShowDiamond(false)}
                value={diamond[type]}
                confirm={num =>
                    setDiamond(state => ({
                        ...state,
                        [type]: num
                    }))
                }
                lower={chatInfo.cp?.price?.[type]?.lower}
                upper={chatInfo.cp?.price?.[type]?.upper}
            />
        </FullScreenPopup>
    );
}

function MediaPopup({
    showMedia,
    setShowMedia,
    chatInfo,
    mode
}: {
    showMedia: boolean;
    setShowMedia: (state: boolean) => void;
    chatInfo: ChatInfo;
    mode: 'user' | 'cp';
}) {
    const [selectedImage, setSelectedImage] = useState<string>('');
    const [selectedVideo, setSelectedVideo] = useState<string>('');
    const [fileType, setFileType] = useState<'image' | 'video' | 'audio'>('image');
    const [showPreview, setShowPreview] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mediaInfo, setMediaInfo] = useState<MediaInfo>({} as MediaInfo);

    const setSnackMessage = useNotifyStore.use.setSnackMessage();

    const switchOpen = () => {
        setShowMedia(!showMedia);
    };

    const switchPreview = () => {
        setShowPreview(!showPreview);
    };

    const closeAll = () => {
        setShowMedia(false);
        setSelectedImage('');
        setSelectedVideo('');
        switchPreview();
        setFileType('image');
    };

    const upload = async () => {
        setLoading(true);
        const { type, file } = mediaInfo;
        const params = {
            detail: {
                width: 0,
                height: 0,
                duration: 0
            },
            cover: '',
            playUrl: ''
        };
        const uploadInfo = await uploadMedia({ type });
        if (uploadInfo.status === 200) {
            if (type === 'video') {
                const { detail, cover } = await fetchVideoContent(file);
                if (detail.duration > 35) {
                    setSnackMessage({ message: '影片長度請勿超過 30 秒' });
                    closeAll();
                    setLoading(false);
                    return;
                }
                const coverFile = await dataURLtoFile(cover);
                const uploadCover = await uploadMedia({ type: 'image' });
                if (uploadCover.status === 200) {
                    try {
                        await uploadFileToS3(coverFile, uploadCover.data.upload);
                        params.detail = detail;
                        params.cover = uploadCover.data.play_url;
                    } catch {
                        setSnackMessage({
                            message: '影片長度過長或影片格式有誤，請重新處理後上傳！'
                        });
                    }
                }
            }
            await uploadFileToS3(file, uploadInfo.data.upload);
            params.playUrl = uploadInfo.data.play_url;
            setLoading(false);
        }
        return params;
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        const reader = new FileReader();

        reader.onload = () => {
            switchOpen();
            switchPreview();
            if (file && file.type.includes('image')) {
                const fileUrl = URL.createObjectURL(file);
                setMediaInfo({ file, type: 'image' });
                setFileType('image');
                setSelectedImage(fileUrl);
            }
            if (file && file.type.includes('video')) {
                const fileUrl = URL.createObjectURL(file);
                setMediaInfo({ file, type: 'video' });
                setFileType('video');
                setSelectedVideo(fileUrl);
            }
        };

        if (file) {
            reader.readAsDataURL(file);
        }
        event.target.value = '';
    };

    return (
        <>
            <SwipeableDrawer
                PaperProps={{
                    style: {
                        borderTopLeftRadius: '16px',
                        borderTopRightRadius: '16px'
                    }
                }}
                disableSwipeToOpen
                anchor="bottom"
                onClose={switchOpen}
                onOpen={switchOpen}
                open={showMedia}
                swipeAreaWidth={20}
                transitionDuration={{ enter: 300, exit: 200 }}
            >
                <div className={style.mediaPopup}>
                    <div className={style.photo}>
                        <PhotoPickIcon />
                        <p>照片或影片</p>
                        <input
                            className={style.hiddenInput}
                            type="file"
                            id="fileInputChat"
                            accept="image/*, video/*"
                            onChange={handleFileChange}
                        />
                    </div>
                    {/* <div className={style.voice}>
                        <VoicePickIcon />
                        <p>錄音</p>
                    </div> */}
                </div>
            </SwipeableDrawer>
            <Preview
                showPreview={showPreview}
                selectedVideo={selectedVideo}
                selectedImage={selectedImage}
                back={closeAll}
                chatInfo={chatInfo}
                type={fileType}
                closeAll={closeAll}
                loading={loading}
                mode={mode}
                upload={upload}
            />
        </>
    );
}

export default MediaPopup;
