import Image from 'next/image';

import MediaWithAuthHeader from '@/components/mediaWithAuthHeader/mediaWithAuthHeader';
import { ResMessage } from '@/types/chat';

import DiamondIcon from './img/diamond.png';

import style from './contentImage.module.scss';

function ContentImage({
    message,
    mode = 'user',
    switchDetail
}: {
    message: ResMessage;
    mode: 'cp' | 'user';
    switchDetail: (message: ResMessage) => void;
}) {
    const status = {
        0: mode === 'user' ? '圖片已傳送！' : '對方已傳送圖片！',
        1: '已過期',
        2: mode === 'user' ? '圖片已傳送！' : '對方已傳送圖片！',
        3: mode === 'user' ? '對方已解鎖！' : '已解鎖！'
    };
    const showDetail = () => {
        if (message.status === 1) return;
        switchDetail(message);
    };

    return (
        <div className={style.media} onClick={showDetail}>
            <div
                style={{
                    aspectRatio: `${message.msg.width || message.msg.detail?.width} / ${
                        message.msg.height || message.msg.detail?.height
                    }`
                }}
            >
                <div
                    className={style.fit}
                    style={{
                        width: message.msg.width || message.msg.detail?.width
                    }}
                >
                    {mode === 'user' ? (
                        <MediaWithAuthHeader className={style.mediaImg} src={message.msg.cover} />
                    ) : message.status === 3 || message.status === 0 ? (
                        <MediaWithAuthHeader className={style.mediaImg} src={message.msg.cover} />
                    ) : (
                        <Image
                            src={message.msg.cover}
                            width={message.msg.width || 170}
                            height={message.msg.height || 144}
                            alt="img"
                        />
                    )}
                </div>
            </div>
            <div className={style.noted}>
                <p>{status[message.status as 0 | 1 | 2 | 3]}</p>
                {message.msg.diamond > 0 && message.msg.is_lock && (
                    <p className={style.diamond}>
                        <Image src={DiamondIcon} width={12} height={12} alt="diamond" />
                        {message.msg.diamond}
                    </p>
                )}
            </div>
        </div>
    );
}

export default ContentImage;
