'use client';

import { useState } from 'react';

import { unlockCpVideo } from '@/api/couple/cpInfo';
import CpMediaInfoCard from '@/components/cp/cpMediaInfoCard/cpMediaInfoCard';
import { useDepositStore } from '@/store/deposit';
import { useNotifyStore } from '@/store/notify';
import { CpVideoType, CpInfoType } from '@/types/cpType';

function VideoInfo({
    userInfo,
    videoInfo,
    onClose
}: {
    userInfo: CpInfoType;
    videoInfo: CpVideoType;
    onClose?: () => void;
}) {
    const [videoData, setVideoData] = useState<CpVideoType>(videoInfo);
    const setSnackMessage = useNotifyStore.use.setSnackMessage();
    const setDepositPopup = useDepositStore.use.setDepositPopup();

    const handleUnLock = async () => {
        const res = await unlockCpVideo({ userId: userInfo.user_id, videoId: videoInfo.id });
        if (res.status === 200) {
            setSnackMessage({ message: '解鎖成功' });
            setVideoData(res.data);
        } else {
            setSnackMessage({ message: '解鎖失敗' });
            if (res.errorMsg === '鑽石不足') {
                setDepositPopup();
            }
        }
    };

    return (
        <CpMediaInfoCard
            userInfo={userInfo}
            mediaData={videoData}
            handleUnLock={handleUnLock}
            isVideo
            onClose={onClose}
        />
    );
}

export default VideoInfo;
