'use client';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import * as React from 'react';

import BaseButton from '@/components/baseButton/baseButton';
import { useNotifyStore } from '@/store/notify';

import style from './confirmModal.module.scss';

const boxStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    bgcolor: '#171717',
    boxShadow: 24,
    borderRadius: '16px',
    p: '28px 24px 16px 24px',
    gap: '8px',
    display: 'flex',
    flexDirection: 'column',
    outline: 'none'
};

function ConfirmModal() {
    const confirmModalInfo = useNotifyStore.use.confirmModal();
    const setCloseConfirmModal = useNotifyStore.use.setCloseConfirmModal();

    const onCloseHandler = (
        event: React.SyntheticEvent,
        reason?: 'backdropClick' | 'escapeKeyDown'
    ) => {
        if (reason !== 'backdropClick' || confirmModalInfo?.backdropClickMode)
            setCloseConfirmModal();
    };

    const confirmHandler = () => {
        confirmModalInfo.onConfirm();
        setCloseConfirmModal();
    };

    return (
        <Modal open={confirmModalInfo.open} onClose={onCloseHandler}>
            <Box sx={boxStyle}>
                <div className={style.contentContainer}>
                    <div className={style.title}>{confirmModalInfo?.title || ''}</div>
                    <div className={style.content}>{confirmModalInfo?.content || ''}</div>
                </div>
                {confirmModalInfo.confirmText ? (
                    <BaseButton onClick={confirmHandler}>{confirmModalInfo.confirmText}</BaseButton>
                ) : null}
                {confirmModalInfo.cancelText ? (
                    <BaseButton onClick={setCloseConfirmModal} type="outline">
                        {confirmModalInfo.cancelText}
                    </BaseButton>
                ) : null}
            </Box>
        </Modal>
    );
}

export default ConfirmModal;
