import { useEffect } from 'react';

import { postRead } from '@/lib/websocket';
import { ResMessage } from '@/types/chat';
import { UserInfo } from '@/types/user';

function useSyncRead({
    openChat,
    chatMessageList,
    userInfo
}: {
    openChat: boolean;
    chatMessageList: ResMessage[];
    userInfo: UserInfo;
}) {
    useEffect(() => {
        if (openChat && chatMessageList.length > 0) {
            const newMessage = chatMessageList[chatMessageList.length - 1];
            if (newMessage.from !== userInfo.user_id) {
                postRead(newMessage.session_id);
            }
        }
    }, [openChat, chatMessageList, userInfo.user_id]);
}

export default useSyncRead;
