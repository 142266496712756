'use client';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import * as React from 'react';

import { useNotifyStore } from '@/store/notify';

import IconAlert from './img/alert.svg';
import IconClose from './img/close.svg';

import style from './messageModal.module.scss';

const boxStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 250,
    bgcolor: '#171717',
    boxShadow: 24,
    borderRadius: '16px',
    p: '16px',
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    alignItems: 'center'
};

const IconMap = {
    warning: <IconAlert />
};

function MessageModal() {
    const messageModalInfo = useNotifyStore.use.messageModal();
    const setCloseMessageModal = useNotifyStore.use.setCloseMessageModal();

    return (
        <Modal open={messageModalInfo.open} onClose={setCloseMessageModal}>
            <Box sx={boxStyle}>
                <div className={style.close}>
                    <IconClose onClick={setCloseMessageModal} />
                </div>
                <div className={style.contentContainer}>
                    {IconMap[messageModalInfo.type] ? IconMap[messageModalInfo.type] : null}
                    <div className={style.content}>{messageModalInfo.content}</div>
                </div>
            </Box>
        </Modal>
    );
}

export default MessageModal;
