import { usePathname } from 'next/navigation';
import { useEffect, useCallback } from 'react';

function useHistory(state: boolean, mode: string, to: string, switchChat: () => void) {
    const pathname = usePathname();
    const handlePopstate = useCallback(() => {
        switchChat();
    }, [switchChat]);
    useEffect(() => {
        if (state) {
            window.addEventListener('popstate', handlePopstate);
            if (pathname === '/chat') return;
            window.history.pushState(null, '', `/chat?mode=${mode}&to=${to}`);
        } else {
            window.removeEventListener('popstate', handlePopstate);
        }
    }, [state, mode, to, switchChat, handlePopstate]);
}

export default useHistory;
