'use client';
import Cookies from 'js-cookie';
import { useEffect, useRef } from 'react';

import { initWebsocket } from '@/lib/websocket';
import { useUserStore } from '@/store/user';

function WebSocket() {
    const isLogin = useUserStore.use.isLogin();
    const userInfo = useUserStore.use.userInfo();
    const connected = useRef(false);
    useEffect(() => {
        if (!isLogin || connected.current) return;
        connected.current = true;
        const token = Cookies.get('access');
        token && initWebsocket(token);
    }, [isLogin, userInfo]);

    return <></>;
}
export default WebSocket;
