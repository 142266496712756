import { ResMessage } from '@/types/chat';

import style from './contentText.module.scss';

function ContentText({ message, role }: { message: ResMessage; role: 'user' | 'cp' }) {
    const contentList = message.msg.content.split('\n');

    return (
        <div className={`${style.content} ${role === 'user' ? style.user : style.cp}`}>
            <div className={style.topBorder}></div>
            {contentList.map((item, idx) => {
                return <p key={idx}>{item}</p>;
            })}
            <div className={style.bottomBorder}></div>
        </div>
    );
}

export default ContentText;
