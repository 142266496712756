import { useRouter } from 'next/navigation';

import CpAvatar from '@/components/cp/cpAvatar/cpAvatar';
import MoreOption from '@/features/moreOption/moreOption';
import { useDepositStore } from '@/store/deposit';
import { ChatType } from '@/types/chat';

import ArrowIcon from './img/arrow.svg';
import DimondIcon from './img/diamond.svg';

import style from './chatHeader.module.scss';

function ChatHeader({
    currentChat,
    switchChat,
    isCp,
    pageMode,
    isOfficial
}: {
    currentChat: ChatType;
    switchChat: () => void;
    isCp: boolean;
    pageMode: boolean;
    isOfficial: boolean;
}) {
    const router = useRouter();
    const setDepositPopup = useDepositStore.use.setDepositPopup();

    const handleToCpPage = () => {
        if (currentChat.mode === 'cp') return;
        window.location.href = `/cp/${currentChat.user_id}/i`;
        switchChat();
    };

    const closePage = () => {
        if (pageMode) {
            if (currentChat.mode === 'user') {
                router.push('/chatroom');
            } else {
                router.push('/chatroom/cp');
            }
            switchChat();
        } else {
            window.history.back();
        }
    };

    return (
        <div className={style.placeHolder}>
            <div className={style.header}>
                <div className={style.left}>
                    <div className={style.backBtn} onClick={closePage}>
                        <ArrowIcon />
                    </div>
                    <div className={style.cpInfoBar} onClick={handleToCpPage}>
                        {currentChat.avatar && (
                            <CpAvatar
                                src={currentChat.avatar}
                                tag={currentChat.label}
                                alt={currentChat.nickname}
                            />
                        )}
                        <div className={style.info}>
                            <div className={style.nameBar}>
                                <div className={style.name}>{currentChat.nickname}</div>
                                {currentChat.status === 1 && <div className={style.online} />}
                            </div>
                            {!isOfficial && <div className={style.tag}>@{currentChat.account}</div>}
                        </div>
                    </div>
                </div>
                <div className={style.right}>
                    {!isCp && <DimondIcon onClick={setDepositPopup} />}
                    <MoreOption />
                </div>
            </div>
        </div>
    );
}

export default ChatHeader;
