'use client';

import { useState } from 'react';

import { unlockCpImage } from '@/api/couple/cpInfo';
import CpMediaInfoCard from '@/components/cp/cpMediaInfoCard/cpMediaInfoCard';
import { useDepositStore } from '@/store/deposit';
import { useNotifyStore } from '@/store/notify';
import { CpImageType, CpInfoType } from '@/types/cpType';

function ImageInfo({
    userInfo,
    imageInfo,
    onClose
}: {
    userInfo: CpInfoType;
    imageInfo: CpImageType;
    onClose?: () => void;
}) {
    const [imageData, setImageData] = useState<CpImageType>(imageInfo);
    const setSnackMessage = useNotifyStore.use.setSnackMessage();
    const setDepositPopup = useDepositStore.use.setDepositPopup();

    const handleUnLock = async () => {
        const res = await unlockCpImage({ userId: userInfo.user_id, imageId: imageInfo.id });
        if (res.status === 200) {
            setSnackMessage({ message: '解鎖成功' });
            setImageData(res.data);
        } else {
            setSnackMessage({ message: res.errorMsg });
            if (res.errorMsg === '鑽石不足') {
                setDepositPopup();
            }
        }
    };

    return (
        <CpMediaInfoCard
            onClose={onClose}
            userInfo={userInfo}
            mediaData={imageData}
            handleUnLock={handleUnLock}
        />
    );
}

export default ImageInfo;
