'use client';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

import SessionStorageManager from '@/lib/sessionStorageManager';

interface UtmParams {
    [key: string]: string;
}

function UtmSetting() {
    const searchParams = useSearchParams();
    const getUtmParams = (): UtmParams => {
        const utmParams: UtmParams = {};
        searchParams.forEach((value: string, key: string) => {
            if (key.startsWith('utm_')) {
                utmParams[key] = value;
            }
        });
        return utmParams;
    };

    const utmParams = getUtmParams();

    useEffect(() => {
        if (Object.keys(utmParams).length > 0) {
            SessionStorageManager.set('utm_config', JSON.stringify(utmParams));
        }
    }, [utmParams]);
    return null;
}

export default UtmSetting;
