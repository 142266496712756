import { usePathname } from 'next/navigation';
import { useCallback } from 'react';

import { getApiPath } from '@/utils/handleENV';

const gClientId = '752253467398-6j6o8ufle3a4g1il0ckt9igm85rqb1lp.apps.googleusercontent.com';
const fetchGoogleSignInUrl = '/auth/google/callback';

const useGoogleAuth = () => {
    const pathname = usePathname();
    const apiPath = getApiPath();
    const loginWithGoogle = useCallback(() => {
        const stateInfo = {
            goto: pathname,
            domain: window.location.host
        };
        const stateParam = JSON.stringify(stateInfo);
        const authGoogleApiCallbackUrl = `${apiPath}${fetchGoogleSignInUrl}`;

        const url = new URL('https://accounts.google.com/o/oauth2/v2/auth');
        url.searchParams.append('client_id', gClientId);
        url.searchParams.append('redirect_uri', authGoogleApiCallbackUrl);
        url.searchParams.append('response_type', 'code');
        url.searchParams.append('scope', 'profile email');
        url.searchParams.append('state', stateParam);
        url.searchParams.append('prompt', 'select_account'); // force to select account

        window.location.href = url.toString();
    }, [pathname, apiPath]);

    return {
        loginWithGoogle
    };
};

export default useGoogleAuth;
