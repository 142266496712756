'use client';
import { ReactNode } from 'react';

import { createBaseStore } from '@/store/base';

function ClientBaseConfig({
    children,
    pwaTip,
    env
}: {
    children: ReactNode;
    pwaTip: boolean;
    env: string;
}) {
    createBaseStore({ showPwaTip: pwaTip, envParams: env });
    return <>{children}</>;
}

export default ClientBaseConfig;
