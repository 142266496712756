import style from './selectCard.module.scss';

interface Props {
    selected: boolean;
    children: React.ReactNode;
    onClick?: () => void;
    className?: string;
    label?: string;
}

function SelectCard({ selected, children, className, label, onClick }: Props) {
    return (
        <div
            onClick={onClick}
            className={`${style.selectCard} ${selected ? style.selected : null} ${className}`}
        >
            {label ? <div className={style.label}>{label}</div> : null}
            {children}
        </div>
    );
}

export default SelectCard;
