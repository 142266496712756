import { type Product } from '@/api/shop/shop';
import { initStore } from '@/lib/store';
import type { StoreWithSelectors } from '@/lib/store';

interface baseState {
    depositPopup: boolean;
    setDepositPopup: () => void;
    depositProducts: Product[];
    setDepositProducts: (products: Product[]) => void;
}

let isInit = true;
let useDepositStore: StoreWithSelectors<baseState>;

const initialState = (set: (updater: (state: baseState) => Partial<baseState>) => void) => ({
    depositPopup: false,
    setDepositPopup: () => {
        sessionStorage.setItem('redirect', window.location.href);
        set(state => ({
            depositPopup: !state.depositPopup
        }));
    },
    setDepositProducts: (products: Product[]) => {
        set(() => ({
            depositProducts: products
        }));
    },
    depositProducts: []
});

const createDepositStore = () => {
    if (isInit) {
        useDepositStore = initStore<baseState>(initialState);
        isInit = false;
    }
};

export { createDepositStore, useDepositStore };
