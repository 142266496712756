'use client';
import { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import { useUserStore } from '@/store/user';
import BaseButton from '@/components/baseButton/baseButton';

import LogoImg from './img/logo.svg';

import style from './teenagerWarning.module.scss';

function TeenagerWarning() {
    const userInfo = useUserStore.use.userInfo();
    const isLogin = useUserStore.use.isLogin();
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        if (isLogin && userInfo.is_completed) {
            sessionStorage.setItem('hasOver18', '1');
            return;
        }
        setIsOpen(sessionStorage.getItem('hasOver18') !== '1');
    }, []);

    const closePopup = (_: object, reson: string) => {
        if (reson === 'escapeKeyDown' || reson === 'backdropClick') return;
        setIsOpen(false);
    };
    const agree = () => {
        sessionStorage.setItem('hasOver18', '1');
        setIsOpen(false);
    };
    const reject = () => {
        sessionStorage.setItem('hasOver18', '0');
        setIsOpen(false);
        location.href = 'https://whywhy.crisp.help/zh-tw/article/5ywn6lks6igy5pio-1wyyc8a/';
    };

    return (
        <Modal open={isOpen} onClose={closePopup} disableEscapeKeyDown>
            <Fade in={isOpen}>
                <div className={style.teenagerWarning}>
                    <div className={style.logo}>
                        <LogoImg />
                    </div>

                    <div className={style.text}>
                        <div className={style.title}>本站已依法自律調整防護措施</div>
                        <div className={style.content}>
                            內含有部分青少年不宜的限制內容，請確認您已成年或達到您所在地區法定成年之年齡。
                        </div>
                    </div>
                    <BaseButton type={'normal'} className={style.btn} onClick={agree}>
                        已滿 18 歲
                    </BaseButton>
                    <BaseButton type={'outline'} className={style.btn} onClick={reject}>
                        未滿 18 歲
                    </BaseButton>
                </div>
            </Fade>
        </Modal>
    );
}

export default TeenagerWarning;
