import { useRef, useState } from 'react';

import { sendMessage } from '@/lib/websocket';
import { useChatStore } from '@/store/chat';
import { useNotifyStore } from '@/store/notify';
import { useUserStore } from '@/store/user';
import { ChatInfo } from '@/types/chat';
import { UserInfo } from '@/types/user';
// import PhotoIcon from './img/photo.svg';
// import VoiceIcon from './img/voice.svg'; TODO: 待後續迭代用

import ChatReply from './chatReply/chatReply';
import GiftIcon from './img/gift.svg';
import PlusIcon from './img/plus.svg';
import SendIcon from './img/send.svg';

import ChatSticker from '../chatSticker/sticker';

import style from './chatFooter.module.scss';

type MessageContent = {
    type: string;
    content: string;
    extra?: {
        type: string;
        video_id?: string;
        image_id?: string;
        drama_id?: string;
        episode_id?: string;
        trailer_id?: string;
    };
};

function ChatFooter({
    chatInfo,
    setShowMedia,
    mode,
    cpIsClose,
    reCalcBodyHeight,
    userInfo
}: {
    chatInfo: ChatInfo;
    setShowMedia: (state: boolean) => void;
    mode: 'user' | 'cp';
    cpIsClose: boolean;
    reCalcBodyHeight: (num: number) => void;
    userInfo: UserInfo;
}) {
    const [buttonType, setButtonType] = useState<'voice' | 'send'>('voice');
    const [typeMessage, setTypeMessage] = useState('');

    const setSnackMessage = useNotifyStore.use.setSnackMessage();
    const resetReplyData = useChatStore.use.resetReplyData();

    const cpInfo = useUserStore.use.cpInfo();
    const disableInput =
        cpIsClose ||
        (mode === 'cp' && !cpInfo.is_active) ||
        chatInfo.status === -1 ||
        userInfo.status === 1;

    const send = () => {
        if (!typeMessage || disableInput) return;
        if (typeMessage.length > 600) {
            setSnackMessage({ message: '超過 600 字上限囉！' });
            return;
        }
        const params: MessageContent = {
            type: 'text',
            content: typeMessage
        };
        setButtonType('voice');

        if (replyData.enable) {
            params.extra = {
                type: 'creation-ref',
                ...replyData
            };

            resetReplyData();
        }
        sendMessage(params);
        setTypeMessage('');
    };

    const isOpenSticker = useChatStore.use.isOpenSticker();
    const setIsOpenSticker = useChatStore.use.setIsOpenSticker();

    const switchSticker = () => {
        if (disableInput) return;
        setIsOpenSticker(!isOpenSticker);
    };

    const messageMap = {
        cpInactive: '小壞壞權限已暫時關閉',
        cpActive: '輸入訊息',
        priced: `訊息每則 ${chatInfo?.user?.price.text.current} 鑽`,
        default: '輸入訊息',
        cpClose: '小壞壞的帳號目前關閉中',
        isFree: '您可免費傳送第 1 則訊息',
        isMute: '您已被禁言，如有問題請洽客服'
    };

    const getKey = () => {
        if (userInfo.status === 1) return 'isMute';
        if ((mode === 'user' && cpIsClose) || chatInfo.status === -1) return 'cpClose';
        if (mode === 'cp' && !cpInfo.is_active) return 'cpInactive';
        if (mode === 'cp') return 'cpActive';
        if (chatInfo.is_free) return 'isFree';
        if (chatInfo?.user?.price.text.current) return 'priced';
        return 'default';
    };

    const holderMessage = messageMap[getKey()];

    const openMediaPanel = () => {
        if (disableInput) return;
        setShowMedia(true);
    };

    const handleBlur = () => {
        if (!typeMessage) setButtonType('voice');
        reCalcBodyHeight(0);
    };
    const onFocus = () => {
        reCalcBodyHeight(36);
        setButtonType('send');
    };

    const textRef = useRef<HTMLTextAreaElement | null>(null);
    const focusTextarea = () => {
        textRef.current && textRef.current.focus();
    };

    const replyData = useChatStore.use.replyData();

    return (
        <div className={`${style.holder} ${replyData.enable ? style.replyHolder : ''}`}>
            <div
                className={`${style.chatFooter} ${isOpenSticker && style.chatFooterWithSticker} ${
                    replyData.enable ? style.chatReply : ''
                }`}
            >
                {replyData.enable && <ChatReply replyData={replyData} />}
                <div className={style.group}>
                    <div className={style.photo} onClick={openMediaPanel}>
                        <PlusIcon />
                    </div>
                    <div className={style.input}>
                        <textarea
                            ref={textRef}
                            placeholder={holderMessage}
                            disabled={disableInput}
                            onFocus={onFocus}
                            onBlur={handleBlur}
                            value={typeMessage}
                            onChange={e => setTypeMessage(e.target.value)}
                            onClick={focusTextarea}
                        />

                        <div className={style.gift} onClick={switchSticker}>
                            <GiftIcon />
                        </div>
                    </div>

                    <div className={style.send} onClick={send}>
                        {buttonType === 'voice' ? <SendIcon /> : <SendIcon />}
                    </div>
                </div>
            </div>
            <ChatSticker
                mode={mode}
                isOpen={isOpenSticker}
                chatInfo={chatInfo}
                switchSticker={switchSticker}
            />
        </div>
    );
}

export default ChatFooter;
