'use client';

import { Crisp } from 'crisp-sdk-web';
import { useEffect } from 'react';

import { useUserStore } from '@/store/user';

function CrispChat() {
    const isLogin = useUserStore.use.isLogin();
    const userInfo = useUserStore.use.userInfo();

    useEffect(() => {
        Crisp.configure('c0b46ec9-1274-4572-97f7-7985cc8d51f8');
        $crisp.push(['do', 'chat:hide']);

        $crisp.push([
            'on',
            'chat:opened',
            function () {
                $crisp.push(['do', 'chat:show']);
            }
        ]);

        $crisp.push([
            'on',
            'chat:closed',
            function () {
                $crisp.push(['do', 'chat:hide']);
            }
        ]);
    });

    useEffect(() => {
        if (!isLogin) return;
        userInfo.email && $crisp.push(['set', 'session:data', ['email', userInfo.email]]);
        userInfo.account && $crisp.push(['set', 'session:data', ['account', userInfo.account]]);
        userInfo.gender && $crisp.push(['set', 'session:data', ['gender', userInfo.gender]]);
        userInfo.nickname && $crisp.push(['set', 'session:data', ['nickname', userInfo.nickname]]);
    }, [isLogin, userInfo]);

    return null;
}

export default CrispChat;
