import { timestampToHHMM } from '@/lib/timeHandle';
import { ResMessage } from '@/types/chat';

import ContentGift from './contentMedia/contentGift';
import ContentImage from './contentMedia/contentImage';
import ContentText from './contentMedia/contentText';
import ContentVideo from './contentMedia/contentVideo';
import DoubleTickIcon from './img/doubleTick.svg';
// import TickIcon from './img/tick.svg';

import style from './userBubble.module.scss';

function UserBubble({
    message,
    switchDetail,
    lastReadAt
}: {
    message: ResMessage;
    switchDetail: (message: ResMessage) => void;
    lastReadAt: string;
}) {
    const tickStatus = lastReadAt > message.created_at ? 'read' : 'send';

    return (
        <div className={style.userBox}>
            <div className={style.info}>
                <div className={style.tick}>
                    {/* {tickStatus === 'send' && <TickIcon />} */}
                    {tickStatus === 'read' && <DoubleTickIcon />}
                </div>
                <div className={style.date}>{timestampToHHMM(message.created_at)}</div>
            </div>

            {message.msg.type === 'text' && <ContentText role="user" message={message} />}
            {message.msg.type === 'gift' && <ContentGift message={message} />}
            {message.msg.type === 'image' && (
                <ContentImage message={message} mode="user" switchDetail={switchDetail} />
            )}
            {message.msg.type === 'video' && (
                <ContentVideo message={message} mode="user" switchDetail={switchDetail} />
            )}
        </div>
    );
}

export default UserBubble;
