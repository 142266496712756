import { useEffect, useState } from 'react';

import { getCpInfo, getCpPornAlbumInfo, getCpPornAlbumEpisodeVideoInfo } from '@/api/couple/cpInfo';
import { CpPornAlbumEpisodeVideo, CpPornAlbumInfo, CpInfoType } from '@/types/cpType';

import VideoInfo from '../../app/cp/[userId]/(porn)/p/[albumId]/e/[episodeId]/videoInfo';

function EpisodeVideoPage({
    userId,
    albumId,
    episodeId,
    onClose
}: {
    userId: string;
    albumId: string;
    episodeId: string;
    onClose: () => void;
}) {
    const [cpRes, setCpRes] = useState<CpInfoType>({} as CpInfoType);
    const [albumRes, setAlbumRes] = useState<CpPornAlbumInfo>({} as CpPornAlbumInfo);
    const [videoRes, setVideoRes] = useState<CpPornAlbumEpisodeVideo>(
        {} as CpPornAlbumEpisodeVideo
    );

    useEffect(() => {
        const fetchData = async () => {
            const res = await getCpInfo(userId);
            if (res.status === 200) {
                setCpRes(res.data);
            }

            const resAlbum = await getCpPornAlbumInfo({
                userId,
                albumId
            });
            if (resAlbum.status === 200) {
                setAlbumRes(resAlbum.data);
            }

            const resVideo = await getCpPornAlbumEpisodeVideoInfo({
                userId,
                albumId,
                episodeId
            });
            if (resVideo.status === 200) {
                setVideoRes(resVideo.data);
            }
        };
        fetchData();
    }, [userId, albumId, episodeId]);

    return (
        <>
            {cpRes.account && albumRes.id && videoRes.id && (
                <VideoInfo
                    userInfo={cpRes}
                    videoInfo={videoRes}
                    albumInfo={albumRes}
                    onClose={onClose}
                />
            )}
        </>
    );
}

export default EpisodeVideoPage;
