import Image from 'next/image';

import { ResMessage } from '@/types/chat';

import style from './contentGift.module.scss';

function ContentGift({ message }: { message: ResMessage }) {
    return (
        <div className={style.gift}>
            <Image src={message.msg.content} width={150} height={150} alt="whywhy" />
        </div>
    );
}

export default ContentGift;
