import MediaWithAuthHeader from '@/components/mediaWithAuthHeader/mediaWithAuthHeader';

import style from './quote.module.scss';

function Quote({ data }: { data: { url: string; description: string } }) {
    return (
        <div className={style.quote}>
            <div className={style.content}>{data.description}</div>
            <div className={style.img}>
                <MediaWithAuthHeader src={data.url} />
            </div>
        </div>
    );
}

export default Quote;
