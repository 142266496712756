import { fetcher } from '@/lib/fetcher';

export interface Product {
    id: number;
    name: string;
    amount: number;
    currency: string;
    diamond: number;
    label: string;
    bonus: number;
}

/** 商品列表 */
export const getProducts = () => {
    return fetcher<Product[], null>({
        url: `/shop/v1/products`,
        method: 'GET'
    });
};

interface CheckoutProductResponse {
    order: {
        id: string;
        user_id: string;
        amount: number;
        currency: string;
        diamond: number;
        bonus: number;
        status: number;
    };
    payment_methods: {
        id: number;
        method: string;
        provider: string;
    }[];
}

interface CheckoutProductParams {
    name: string;
}

/** 購買商品 */
export const checkoutProduct = (data: CheckoutProductParams) => {
    return fetcher<CheckoutProductResponse, CheckoutProductParams>({
        url: `/shop/v1/products/checkout`,
        method: 'POST',
        data
    });
};

interface GetPayMethodsParams {
    order_id: string;
    provider: string;
    method: string;
    card_number: string;
    card_expire: string;
    card_cvc: string;
    card_token?: string;
    email: string;
    return_url: string;
    prime?: string;
}

interface GetPayMethodsResponse {
    type: string;
    redirect_url: string;
}

/** 取得付款方式 */
export const getPayMethodsComplete = (data: GetPayMethodsParams) => {
    return fetcher<GetPayMethodsResponse, GetPayMethodsParams>({
        url: `/shop/v1/products/complete`,
        method: 'POST',
        data
    });
};

interface GetOrderParams {
    id: string;
}

interface GetOrderResponse {
    id: string;
    user_id: string;
    amount: number;
    currency: string;
    diamond: number;
    bonus: number;
    status: number;
}

export const getOrderStatus = (data: GetOrderParams) => {
    return fetcher<GetOrderResponse, null>({
        url: `/shop/v1/order/${data.id}`,
        method: 'GET'
    });
};

export interface GetBannerResponse {
    seq: number;
    enabled: boolean;
    img_url: string;
    target_url: string | null;
    country: string;
    action: string;
}

export const getBanner = (countryCode: string) => {
    return fetcher<{ result: GetBannerResponse[] }, { country_code: string }>({
        url: `/shop/banner`,
        method: 'GET',
        data: {
            country_code: countryCode
        }
    });
};
