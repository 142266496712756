import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import { useBaseStore } from '@/store/base';
import { useUserStore } from '@/store/user';

function useLoginHandle() {
    const isLogin = useUserStore.use.isLogin();
    const setLoginPopup = useBaseStore.use.setLoginPopup();
    const pathname = usePathname();

    useEffect(() => {
        if (isLogin || !pathname.includes('/chat')) return;
        setLoginPopup(true);
    }, [isLogin, setLoginPopup, pathname]);
}

export default useLoginHandle;
