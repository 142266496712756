import { fetcher } from '@/lib/fetcher';
import { NotifyItemType } from '@/types/notifyCenter';

export const getNotifyUnread = () => {
    return fetcher<{ count: number }, null>({
        url: '/notify-center/unread',
        method: 'GET'
    });
};

export const getNotifyListAll = (data: { limit: number; page: number }) => {
    return fetcher<NotifyItemType[], { limit: number; page: number }>({
        url: '/notify-center/list/all',
        method: 'GET',
        data
    });
};

export const getNotifyListCouple = (data: { limit: number; page: number }) => {
    return fetcher<NotifyItemType[], { limit: number; page: number }>({
        url: '/notify-center/list/couple',
        method: 'GET',
        data
    });
};
/** 更新通知中心已讀 */
export const readNotify = (id: string) => {
    return fetcher<null, null>({
        url: `/notify-center/${id}/read`,
        method: 'PUT'
    });
};

export const updateFcmToken = (token: string, uuid: string) => {
    return fetcher<null, { fcm: string }>({
        url: '/user/v1/me/notify-token',
        method: 'POST',
        headers: {
            'x-device-id': uuid
        },
        data: {
            fcm: token
        }
    });
};
