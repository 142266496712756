import Image from 'next/image';
import { useEffect, useState } from 'react';

import { unLockMedia } from '@/api/chat/chat';
import FullScreenPopup from '@/components/fullScreenPopup/FullScreenPopup';
import Loading from '@/components/loading/Loading';
import MediaWithAuthHeader from '@/components/mediaWithAuthHeader/mediaWithAuthHeader';
import { useChatStore } from '@/store/chat';
import { useDepositStore } from '@/store/deposit';
import { useNotifyStore } from '@/store/notify';
import { useUserStore } from '@/store/user';
import { ChatInfo, ResMessage, UnlockMedia } from '@/types/chat';
import { toDateString } from '@/utils/timeConverter';

import LockIcon from './img/lock.svg';
import PlayIcon from './img/play.svg';

import style from './mediaDetail.module.scss';

interface Props {
    open: boolean;
    detail: ResMessage;
    close: () => void;
    chatInfo: ChatInfo;
}
function DetailHeader({ nickname, detail }: { nickname: string; detail: ResMessage }) {
    return (
        <div className={style.title}>
            <div className={style.name}>{nickname}</div>
            <div className={style.time}>{toDateString(detail.created_at)}</div>
        </div>
    );
}

function MediaDetail({ open, detail, close, chatInfo }: Props) {
    const ownerInfo = chatInfo.participants.filter(item => item.user_id === detail.from);
    const userInfo = useUserStore.use.userInfo();
    const setSnackMessage = useNotifyStore.use.setSnackMessage();
    const [unlockInfo, setUnlockInfo] = useState<UnlockMedia>({} as UnlockMedia);
    const [loading, setLoading] = useState(false);
    const setDepositPopup = useDepositStore.use.setDepositPopup();
    const Unlock = async () => {
        setLoading(true);
        const res = await unLockMedia({
            sessionId: detail.session_id,
            messageId: detail.id
        });

        if (res.status === 200) {
            setSnackMessage({ message: '解鎖成功！' });
            setUnlockInfo(res.data);
            useChatStore.getState().setChatMapMessageUnlock(res.data.session_id, res.data);
        } else {
            setSnackMessage({ message: res.errorMsg });
            setDepositPopup();
        }
        setLoading(false);
    };

    useEffect(() => {
        if (!open) setUnlockInfo({} as UnlockMedia);
    }, [open]);

    return (
        <FullScreenPopup
            isOpen={open}
            close={close}
            title={<DetailHeader nickname={ownerInfo?.[0]?.nickname} detail={detail} />}
        >
            <div className={style.mediaDetail}>
                {unlockInfo.msg?.content ? (
                    <MediaWithAuthHeader
                        src={unlockInfo.msg.content}
                        isVideo={detail.msg?.type === 'video'}
                    />
                ) : (
                    <div className={style.box}>
                        {userInfo.user_id !== detail.from && detail.msg?.is_lock ? (
                            <Image
                                src={
                                    detail.msg?.type === 'video'
                                        ? detail.msg?.detail?.cover || ''
                                        : detail.msg?.cover
                                }
                                width={detail.msg?.width || detail.msg?.detail?.width}
                                height={detail.msg?.height || detail.msg?.detail?.height}
                                alt="img"
                            />
                        ) : userInfo.user_id === detail.from ||
                          detail.status === 0 ||
                          detail.status === 3 ? (
                            <>
                                <MediaWithAuthHeader
                                    isVideo={detail.msg?.type === 'video'}
                                    src={detail.msg?.content}
                                    className={style.media}
                                />
                            </>
                        ) : (
                            <MediaWithAuthHeader
                                isVideo={detail.msg?.type === 'video'}
                                src={
                                    detail.msg?.type === 'video'
                                        ? detail.msg?.detail?.cover || ''
                                        : detail.msg?.cover
                                }
                                className={style.media}
                            />
                        )}
                        {detail.msg?.type === 'video' &&
                            detail.msg?.is_lock &&
                            userInfo.user_id !== detail.from && (
                                <div className={style.play}>
                                    <PlayIcon />
                                </div>
                            )}
                    </div>
                )}
                {userInfo.user_id !== detail.from &&
                    detail.msg?.diamond > 0 &&
                    detail.msg.is_lock &&
                    !unlockInfo.msg?.content && (
                        <>
                            {loading ? (
                                <div className={style.loading}>
                                    <Loading />
                                </div>
                            ) : (
                                <div className={style.bottom} onClick={Unlock}>
                                    <div className={style.btn}>
                                        <LockIcon />
                                        解鎖 | {detail.msg?.diamond} 鑽
                                    </div>
                                </div>
                            )}
                        </>
                    )}
            </div>
        </FullScreenPopup>
    );
}

export default MediaDetail;
