'use client';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { useEffect, useRef } from 'react';

import { useNotifyStore } from '@/store/notify';

import style from './fcmModal.module.scss';

function FcmModal() {
    const data = useNotifyStore.use.fcmModalData();
    const closeFcmModal = useNotifyStore.use.closeFcmModal();
    const timeId = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        if (data.title) {
            if (timeId.current) clearTimeout(timeId.current);
            timeId.current = setTimeout(() => {
                closeFcmModal();
            }, 5000);
        }
    }, [data, closeFcmModal]);

    return (
        <>
            {data.title && (
                <motion.div
                    className={style.fcmModal}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                >
                    <div className={style.cover}>
                        <Image src={data.image} width={48} height={48} alt="WHyWHy user" />
                    </div>
                    <div className={style.content}>
                        <div className={style.title}>{data.title}</div>
                        {data.content && <div className={style.text}>{data.content}</div>}
                    </div>
                </motion.div>
            )}
        </>
    );
}

export default FcmModal;
