'use client';
import Cookies from 'js-cookie';
import { ReactNode, useEffect } from 'react';

import { getUnreadCount } from '@/api/chat/chat';
import { getProducts } from '@/api/shop/shop';
import { useChatStore } from '@/store/chat';
import { useDepositStore } from '@/store/deposit';
import { createUserStore, useUserStore } from '@/store/user';
import { CpInfoType } from '@/types/cpType';
import { UserInfo } from '@/types/user';
import { logout } from '@/utils/logout';

function ChatCount() {
    const isLogin = useUserStore.use.isLogin();

    const setChatUserUnreadInit = useChatStore.use.setChatUserUnreadInit();
    const setChatCpUnreadInit = useChatStore.use.setChatCpUnreadInit();

    useEffect(() => {
        if (isLogin) {
            const fetchUnreadCount = async () => {
                const resUser = await getUnreadCount({ role: 'user' });
                const resCp = await getUnreadCount({ role: 'cp' });
                if (resUser.status === 200) {
                    setChatUserUnreadInit(resUser.data.count);
                }
                if (resCp.status === 200) {
                    setChatCpUnreadInit(resCp.data.count);
                }
            };
            fetchUnreadCount();
        }
    }, [isLogin, setChatUserUnreadInit, setChatCpUnreadInit]);

    return <></>;
}

function HandleProductInfo() {
    const setDepositProducts = useDepositStore.use.setDepositProducts();

    useEffect(() => {
        const fetchGetProducts = async () => {
            const res = await getProducts();
            if (res.status !== 200) return;
            const data = res.data;
            setDepositProducts(data);
        };
        fetchGetProducts();
    }, [setDepositProducts]);

    return <></>;
}

function ClientUserInfo({
    userInfo,
    children,
    clearCookie,
    isCp,
    cpInfo
}: {
    userInfo: UserInfo | null;
    children: ReactNode;
    clearCookie: boolean;
    isCp: boolean;
    cpInfo: CpInfoType;
}) {
    if (typeof window !== 'undefined' && isCp) {
        Cookies.set('isCp', 'on');
    }
    const params = userInfo
        ? {
              isLogin: Boolean(userInfo.access_token),
              userInfo,
              isCp,
              cpInfo
          }
        : {};
    createUserStore(params);

    useEffect(() => {
        const logStyled = (text = '', background = '#4CAF50', color = '#FFFFFF') => {
            const styles = [
                `background: ${background}`,
                `border: 2px solid ${background}`,
                `color: ${color}`,
                'font-weight: bold',
                'padding: 2px 5px',
                'border-radius: 3px',
                'font-size: 14px',
                'text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3)'
            ].join(';');
            // eslint-disable-next-line -- show log
            console.log(`%c${text}`, styles);
        };
        logStyled(`✨Version: ${process.env.version}`, 'rgba(171, 61, 61, 0.8980392157)');
        if (clearCookie) {
            logout();
        }
    }, [clearCookie]);

    return (
        <>
            {children}
            <HandleProductInfo />
            <ChatCount />
        </>
    );
}

export default ClientUserInfo;
