import { useEffect, useRef } from 'react';

import { InfiniteScroll } from '@/components/infiniteScroll/infiniteScroll';
import Loading from '@/components/loading/Loading';
import { useBaseStore } from '@/store/base';
import { useChatStore } from '@/store/chat';
import { ChatInfo, ResMessage } from '@/types/chat';
import { UserInfo } from '@/types/user';
import { chatFormatDate } from '@/utils/timeConverter';

import Quote from './quote/quote';
import CpBubble from './speechBubble/cpBubble';
import UserBubble from './speechBubble/userBubble';

import style from './chatBody.module.scss';

function MainChatList({
    chatMessageList,
    chatInfo,
    userInfo,
    switchDetail
}: {
    chatMessageList: ResMessage[];
    chatInfo: ChatInfo;
    userInfo: UserInfo;
    switchDetail: (message: ResMessage) => void;
}) {
    const renderList = chatMessageList.reduce<{ messages: ResMessage[]; showTime: boolean }[]>(
        (groups, item, idx) => {
            const current = chatFormatDate(item.created_at);
            const prev = idx - 1 >= 0 ? chatFormatDate(chatMessageList[idx - 1].created_at) : '';
            const showTime = !prev || prev !== current;

            if (showTime || groups.length === 0) {
                groups.push({ messages: [item], showTime });
            } else {
                groups[groups.length - 1].messages.push(item);
            }

            return groups;
        },
        []
    );
    const otherOneInfo =
        chatInfo.participants?.[0].user_id === userInfo.user_id
            ? chatInfo.participants?.[1]
            : chatInfo.participants?.[0];

    return (
        <>
            {renderList.map((group, groupIndex) => (
                <div key={groupIndex}>
                    {group.showTime && (
                        <div className={style.dateBox}>
                            <div className={style.dateString}>
                                {chatFormatDate(group.messages[0].created_at)}
                            </div>
                        </div>
                    )}
                    {group.messages.map((item, itemIndex) => {
                        const owner = userInfo.user_id === item.from;
                        return (
                            <div
                                key={`${item.id}_${groupIndex}_${itemIndex}`}
                                className={style.box}
                            >
                                {item.msg.extra?.url && <Quote data={item.msg.extra} />}
                                {owner ? (
                                    <UserBubble
                                        message={item}
                                        switchDetail={switchDetail}
                                        lastReadAt={otherOneInfo.last_read_at}
                                    />
                                ) : (
                                    <CpBubble
                                        message={item}
                                        chatInfo={chatInfo}
                                        switchDetail={switchDetail}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            ))}
        </>
    );
}

function ChatBody({
    chatMessageList,
    chatInfo,
    userInfo,
    switchDetail,
    handleLoadMore,
    isEnd,
    bodyHeight,
    setOriginHeight,
    originHeight,
    isOfficial
}: {
    chatMessageList: ResMessage[];
    chatInfo: ChatInfo;
    userInfo: UserInfo;
    switchDetail: (message: ResMessage) => void;
    handleLoadMore: () => void;
    isEnd: boolean;
    bodyHeight: number;
    setOriginHeight: (num: number) => void;
    originHeight: number;
    isOfficial: boolean;
}) {
    const bodyRef = useRef<HTMLDivElement>(null);
    const keeper = useRef('');
    const isFullscreen = useBaseStore.use.isFullscreen();
    const isIOS = useBaseStore.use.isIOS();
    const replyData = useChatStore.use.replyData();

    useEffect(() => {
        if (bodyRef.current && !isIOS) {
            setOriginHeight(bodyRef.current?.offsetHeight);
        }
    }, []);

    useEffect(() => {
        if (isFullscreen && bodyRef.current && !isIOS && originHeight > 0) {
            bodyRef.current.style.height = `${originHeight - bodyHeight}px`;
        }
    }, [isFullscreen, bodyHeight, originHeight, isIOS]);

    useEffect(() => {
        const lastMessageId = chatMessageList[chatMessageList?.length - 1]?.id;
        if (lastMessageId === keeper.current && chatMessageList.length > 20) {
            if (bodyRef.current) bodyRef.current.scrollTop = 100;
            return;
        }
        if (chatMessageList.length > 0) keeper.current = lastMessageId;
        if (bodyRef.current) bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
    }, [chatMessageList]);

    return (
        <div
            ref={bodyRef}
            className={`${style.chatBody} ${isOfficial ? style.official : ''} ${
                replyData.enable ? style.reply : ''
            }`}
        >
            {!isEnd && (
                <InfiniteScroll onVisible={handleLoadMore}>
                    <div className={style.loading}>
                        <Loading />
                    </div>
                </InfiniteScroll>
            )}
            {chatMessageList.length > 0 && (
                <MainChatList
                    chatMessageList={chatMessageList}
                    chatInfo={chatInfo}
                    userInfo={userInfo}
                    switchDetail={switchDetail}
                />
            )}
        </div>
    );
}

export default ChatBody;
