'use client';
import { motion } from 'framer-motion';
import Cookies from 'js-cookie';
import Image from 'next/image';
import { useEffect } from 'react';

import { useBaseStore } from '@/store/base';

import AddImg from './img/add.png';
import AddChromeImg from './img/addChromeImg.png';
import AddOtherImg from './img/addOther.png';
import ArrowIcon from './img/arrow.svg';
import CloseIcon from './img/close.svg';
import CompleteImg from './img/complete.png';
import LogoImg from './img/logo.png';
import CoverImg from './img/pwaTip.png';
import ShareImg from './img/share.png';
import ShareOther from './img/shareOther.png';

import style from './pwaTip.module.scss';

interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<UserChoice>;
    prompt(): Promise<void>;
}

interface UserChoice {
    outcome: 'accepted' | 'dismissed';
    platform: string;
}

declare global {
    interface WindowEventMap {
        beforeinstallprompt: BeforeInstallPromptEvent;
    }
}

function PwaTip({ position }: { position: 'top' | 'bottom' }) {
    const showPwaTip = useBaseStore.use.showPwaTip();
    const setShowPwaTip = useBaseStore.use.setShowPwaTip();
    const isPWA = useBaseStore.use.isPWA();
    const showPwaTipBottom = useBaseStore.use.showPwaTipBottom();
    const setShowPwaTipBottom = useBaseStore.use.setShowPwaTipBottom();
    const isIOS = useBaseStore.use.isIOS();
    const setDeferredPrompt = useBaseStore.use.setDeferredPrompt();
    const installPWA = useBaseStore.use.installPWA();
    const browser = useBaseStore.use.browser();

    const closeTip = () => {
        Cookies.set('pwaTip', 'off');
        setShowPwaTip(false);
    };

    const closeBottomTip = () => {
        Cookies.set('pwaTipBottom', 'off');
        setShowPwaTipBottom(false);
    };

    const add = () => {
        if (isIOS) {
            setShowPwaTipBottom(true);
        } else {
            installPWA();
        }
    };

    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (event: BeforeInstallPromptEvent) => {
            event.preventDefault();
            setDeferredPrompt(event);
        });
    }, [setDeferredPrompt]);

    const isSafariAndDefault = browser === 'Safari' || browser === '';
    const isChrome = browser === 'Chrome';
    const isOther = browser === 'Edge' || browser === 'Firefox';

    return (
        <>
            {position === 'top' && (
                <div
                    className={`${style.pwaTip} ${!showPwaTip || isPWA ? style.hidden : ''}`}
                    suppressHydrationWarning
                >
                    <CloseIcon onClick={closeTip} className={style.close} />
                    <Image src={LogoImg} width={44} height={44} alt="WHyWHy" />
                    <div className={style.content}>
                        <div className={style.text}>不漏接小壞壞給你的新訊息</div>
                        <div className={style.cta} onClick={add}>
                            加到桌面
                        </div>
                    </div>
                </div>
            )}
            {position === 'bottom' && showPwaTipBottom && !isPWA && (
                <div className={style.pwaTipBottom} suppressHydrationWarning>
                    <motion.div
                        className={style.box}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                    >
                        <div className={style.top}>
                            <div className={style.title}>叮咚！</div>
                            <CloseIcon className={style.close} onClick={closeBottomTip} />
                        </div>
                        <div className={style.cover}>
                            <Image src={CoverImg} alt="WHyWHy" width={240} />
                        </div>
                        <div className={style.content}>不漏接小壞壞給你的最新訊息</div>
                        <div className={`${style.steps} ${isOther ? style.isOther : ''}`}>
                            <div className={style.stepBox}>
                                <div className={style.step}>
                                    {isSafariAndDefault && (
                                        <Image
                                            src={AddImg}
                                            width={84}
                                            height={84}
                                            alt="add WHyWHy"
                                        />
                                    )}
                                    {isChrome && (
                                        <Image
                                            src={AddChromeImg}
                                            width={84}
                                            height={84}
                                            alt="add WHyWHy"
                                        />
                                    )}
                                    {isOther && (
                                        <Image
                                            src={AddOtherImg}
                                            width={84}
                                            height={84}
                                            alt="add WHyWHy"
                                        />
                                    )}
                                </div>
                                <div className={style.text}>
                                    {isSafariAndDefault && '點擊網址下方 “分享”'}
                                    {isChrome && '點擊網址右方 “分享”'}
                                    {isOther && '點擊下方選單'}
                                </div>
                            </div>
                            <ArrowIcon />
                            <div className={style.stepBox}>
                                <div className={style.step}>
                                    {(isSafariAndDefault || isChrome) && (
                                        <Image
                                            src={ShareImg}
                                            width={84}
                                            height={84}
                                            alt="add WHyWHy"
                                        />
                                    )}
                                    {isOther && (
                                        <Image
                                            src={ShareOther}
                                            width={84}
                                            height={84}
                                            alt="add WHyWHy"
                                        />
                                    )}
                                </div>
                                <div className={style.text}>
                                    {(isSafariAndDefault || isChrome) &&
                                        '在選單中找到 “加入主畫面”'}
                                    {isOther && '找到分享'}
                                </div>
                            </div>
                            <ArrowIcon />
                            <div className={style.stepBox}>
                                <div className={style.step}>
                                    {(isSafariAndDefault || isChrome) && (
                                        <Image
                                            src={CompleteImg}
                                            width={84}
                                            height={84}
                                            alt="add WHyWHy"
                                        />
                                    )}
                                    {isOther && (
                                        <Image
                                            src={ShareImg}
                                            width={84}
                                            height={84}
                                            alt="add WHyWHy"
                                        />
                                    )}
                                </div>
                                <div className={style.text}>
                                    {(isSafariAndDefault || isChrome) && '加入成功！'}
                                    {isOther && '在選單中找到 “加入主畫面”'}
                                </div>
                            </div>
                        </div>
                        {isOther && <div className={style.success}>加入成功！</div>}
                    </motion.div>
                </div>
            )}
        </>
    );
}

export default PwaTip;
