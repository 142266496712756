'use client';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import { useState, KeyboardEvent } from 'react';

import { sendEmailforLogin } from '@/api/auth/auth';
import BaseButton from '@/components/baseButton/baseButton';
import BaseInput from '@/components/baseInput/baseInput';
import useGoogleAuth from '@/hooks/useGoogleAuth';
import { isValidEmail } from '@/lib/validate';
import { useBaseStore } from '@/store/base';
import { useNotifyStore } from '@/store/notify';

import CloseIcon from './img/close.svg';
import GoogleImg from './img/google.png';
import LogoImg from './img/logo.png';
import ValidateEmail from './validateEmail';

import style from './login.module.scss';

function Login() {
    const t = useTranslations();
    const loginPopup = useBaseStore.use.loginPopup();
    const setLoginPopup = useBaseStore.use.setLoginPopup();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [validateEmail, setValidateEmail] = useState(false);
    const [emailError, setEmailError] = useState('');

    const setSnackMessage = useNotifyStore.use.setSnackMessage();

    const continueIt = async () => {
        if (!isValidEmail(email)) {
            setSnackMessage({
                message: t('login_email_error'),
                type: 'error'
            });
            setEmailError(t('login_email_error'));
            return;
        }
        setEmailError('');
        setLoading(true);
        await sendEmailforLogin(email);
        setValidateEmail(true);
        setLoading(false);
        setLoginPopup();
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            continueIt();
        }
    };

    const { loginWithGoogle } = useGoogleAuth();
    const googleLogin = () => loginWithGoogle();

    const backToLogin = () => {
        setValidateEmail(false);
        setLoginPopup();
    };

    const closeAll = () => {
        setValidateEmail(false);
    };

    return (
        <>
            <SwipeableDrawer
                PaperProps={{
                    style: {
                        borderTopLeftRadius: '16px',
                        borderTopRightRadius: '16px'
                    }
                }}
                disableSwipeToOpen
                anchor="bottom"
                onClose={() => setLoginPopup()}
                onOpen={() => setLoginPopup()}
                open={loginPopup}
                swipeAreaWidth={20}
                transitionDuration={{ enter: 300, exit: 200 }}
            >
                <div className={style.login}>
                    <div className={style.close} onClick={() => setLoginPopup()}>
                        <CloseIcon />
                    </div>
                    <div className={style.logo}>
                        <Image src={LogoImg} alt="why why" width={94} height={67} />
                    </div>
                    <div className={style.title}>{t('login_title_text')}</div>
                    <BaseInput
                        className={style.email}
                        errorMessage={emailError}
                        label={t('login_input_label')}
                        placeholder={t('login_input_placeholder')}
                        value={email}
                        onChange={value => setEmail(value)}
                        onKeyDown={handleKeyDown}
                    />
                    <div className={style.btn}>
                        <BaseButton loading={loading} onClick={continueIt}>
                            {t('login_continue_button')}
                        </BaseButton>
                        <div className={style.or}>{t('login_or_text')}</div>
                        <BaseButton
                            type="outline"
                            className={style.googleBtn}
                            onClick={googleLogin}
                        >
                            <Image src={GoogleImg} alt="google" width={20} height={20} />{' '}
                            {t('login_google_button')}
                        </BaseButton>
                    </div>
                    <div className={style.terms}>
                        {t('login_terms_agreement_text')}
                        <a href="/myInformation/setting/policy/terms" target="_blank">
                            {t('login_terms_of_service')}
                        </a>
                        {t('login_and_text')}
                        <a href="/myInformation/setting/policy/privacy" target="_blank">
                            {t('login_privacy_policy')}
                        </a>
                    </div>
                </div>
            </SwipeableDrawer>
            <ValidateEmail
                isOpen={validateEmail}
                close={closeAll}
                back={backToLogin}
                email={email}
            />
        </>
    );
}

export default Login;
