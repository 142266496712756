import { useEffect, useState } from 'react';

import { getCpInfo, getCpPornAlbumInfo, getCpPornAlbumTrailerVideoInfo } from '@/api/couple/cpInfo';
import { CpPornAlbumTrailerVideo, CpPornAlbumInfo, CpInfoType } from '@/types/cpType';

import VideoInfo from '../../app/cp/[userId]/(porn)/p/[albumId]/t/[trailerId]/videoInfo';

function CpImageInfoPage({
    userId,
    albumId,
    trailerId,
    onClose
}: {
    userId: string;
    albumId: string;
    trailerId: string;
    onClose: () => void;
}) {
    const [cpRes, setCpRes] = useState<CpInfoType>({} as CpInfoType);
    const [albumRes, setAlbumRes] = useState<CpPornAlbumInfo>({} as CpPornAlbumInfo);
    const [videoRes, setVideoRes] = useState<CpPornAlbumTrailerVideo>(
        {} as CpPornAlbumTrailerVideo
    );

    useEffect(() => {
        const fetchData = async () => {
            const res = await getCpInfo(userId);
            if (res.status === 200) {
                setCpRes(res.data);
            }

            const resAlbum = await getCpPornAlbumInfo({
                userId,
                albumId
            });
            if (resAlbum.status === 200) {
                setAlbumRes(resAlbum.data);
            }

            const resVideo = await getCpPornAlbumTrailerVideoInfo({
                userId,
                albumId,
                trailerId
            });
            if (resVideo.status === 200) {
                setVideoRes(resVideo.data);
            }
        };
        fetchData();
    }, [userId, albumId, trailerId]);

    return (
        <>
            {cpRes.account && albumRes.id && videoRes.id && (
                <VideoInfo
                    userInfo={cpRes}
                    videoInfo={videoRes}
                    albumInfo={albumRes}
                    onClose={onClose}
                />
            )}
        </>
    );
}

export default CpImageInfoPage;
