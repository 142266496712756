import { fetcher } from '@/lib/fetcher';
import { UploadUserImageResponse } from '@/types/common';
import {
    cpCreateImageListType,
    CpCreateVideoListType,
    CpCreatePornAlbumListType,
    CpInfoType,
    CpPornAlbumInfo,
    CpPornAlbumVideoList,
    CpPornAlbumEpisodeVideo,
    CpPornAlbumTrailerVideo,
    creationLabelType
} from '@/types/cpCreateType';

interface CpUpdateProfile {
    avatar: string;
    nickname: string;
    description: string;
    label: string;
}

// 判斷自己是否為cp
export const getOwnCpInfo = (token?: string) => {
    return fetcher<CpInfoType, null>(
        {
            url: `/couple/v1/profile/me`,
            method: 'GET'
        },
        {},
        token
    );
};

export const getCpImageList = ({
    limit,
    page,
    serverAccess
}: {
    limit: number;
    page: number;
    serverAccess?: string;
}) => {
    return fetcher<cpCreateImageListType[], { limit: number; page: number }>(
        {
            url: `/couple/v1/creation/me/image`,
            method: 'GET',
            data: {
                limit,
                page
            }
        },
        {},
        serverAccess
    );
};

export const getCpImageInfo = ({
    imageId,
    serverAccess
}: {
    imageId: string;
    serverAccess?: string;
}) => {
    return fetcher<cpCreateImageListType, null>(
        {
            url: `/couple/v1/creation/me/image/${imageId}`,
            method: 'GET'
        },
        {},
        serverAccess
    );
};

export const getCpVideoList = ({
    limit,
    page,
    serverAccess
}: {
    limit: number;
    page: number;
    serverAccess?: string;
}) => {
    return fetcher<CpCreateVideoListType[], { limit: number; page: number }>(
        {
            url: `/couple/v1/creation/me/video`,
            method: 'GET',
            data: {
                limit,
                page
            }
        },
        {},
        serverAccess
    );
};

export const getCpVideoInfo = ({
    videoId,
    serverAccess
}: {
    videoId: string;
    serverAccess?: string;
}) => {
    return fetcher<CpCreateVideoListType, null>(
        {
            url: `/couple/v1/creation/me/video/${videoId}`,
            method: 'GET'
        },
        {},
        serverAccess
    );
};

export const getCpPornAlbumList = ({
    limit,
    page,
    serverAccess
}: {
    limit: number;
    page: number;
    serverAccess?: string;
}) => {
    return fetcher<CpCreatePornAlbumListType[], { limit: number; page: number }>(
        {
            url: `/couple/v1/creation/me/drama`,
            method: 'GET',
            data: {
                limit,
                page
            }
        },
        {},
        serverAccess
    );
};

// 單一影集資訊
export const getCpPornAlbumInfo = ({
    albumId,
    serverAccess
}: {
    albumId: string;
    serverAccess?: string;
}) => {
    return fetcher<CpPornAlbumInfo, null>(
        {
            url: `/couple/v1/creation/me/drama/${albumId}`,
            method: 'GET'
        },
        {},
        serverAccess
    );
};

// 單一影集列表
export const getCpPornAlbumVideoList = ({
    albumId,
    limit,
    page,
    serverAccess
}: {
    albumId: string;
    limit: number;
    page: number;
    serverAccess?: string;
}) => {
    return fetcher<CpPornAlbumVideoList[], { limit: number; page: number }>(
        {
            url: `/couple/v1/creation/me/drama/${albumId}/episode`,
            method: 'GET',
            data: {
                limit,
                page
            }
        },
        {},
        serverAccess
    );
};

// 影片
export const getCpPornAlbumEpisodeVideoInfo = ({
    albumId,
    episodeId,
    serverAccess
}: {
    albumId: string;
    episodeId: string;
    serverAccess?: string;
}) => {
    return fetcher<CpPornAlbumEpisodeVideo, null>(
        {
            url: `/couple/v1/creation/me/drama/${albumId}/episode/${episodeId}`,
            method: 'GET'
        },
        {},
        serverAccess
    );
};

// 預告片
export const getCpPornAlbumTrailerVideoInfo = ({
    albumId,
    trailerId,
    serverAccess
}: {
    albumId: string;
    trailerId: string;
    serverAccess?: string;
}) => {
    return fetcher<CpPornAlbumTrailerVideo, null>(
        {
            url: `/couple/v1/creation/me/drama/${albumId}/trailer/${trailerId}`,
            method: 'GET'
        },
        {},
        serverAccess
    );
};

export interface CoverItem {
    id: string;
    url: string;
    status: 0 | 1 | 2; //'0 審核中 1 已過審 2 未過審'
    reason: string;
    editable: boolean;
}

export interface GetProfileEditResponse {
    account: string;
    cover: CoverItem[];
    avatar: string;
    nickname: string;
    user_id: string;
    label: 'evil' | 'angel';
    is_active: boolean;
    is_complete: boolean;
    description: string;
    birthday: string;
    auto_message?: string;
}

export const getProfileEdit = ({ serverAccess }: { serverAccess?: string }) => {
    return fetcher<GetProfileEditResponse, null>(
        {
            url: `/couple/v1/profile/me/edit`,
            method: 'GET'
        },
        {},
        serverAccess
    );
};

export const updateProfile = (data: CpUpdateProfile) => {
    return fetcher<unknown, CpUpdateProfile>({
        url: '/couple/v1/profile/me',
        method: 'PUT',
        data
    });
};

/** 取得cp影片、照片、影片封面、迷片封面、預告片、影集...等上傳網址 */
export const getUploadCoupleUrl = (
    type:
        | 'video'
        | 'image'
        | 'cover'
        | 'drama'
        | 'trailer-cover'
        | 'trailer-video'
        | 'episode-cover'
        | 'episode-video'
) => {
    return fetcher<UploadUserImageResponse, null>({
        url: `/couple/v1/${type}/upload-url`,
        method: 'POST'
    });
};

/** 取得cp頭像上傳網址 */
export const getCpAvatarUrl = () => {
    return fetcher<UploadUserImageResponse, null>({
        url: '/couple/v1/avatar/upload-url',
        method: 'POST'
    });
};

/** 建立cp照片 */
export const addImage = (data: {
    url: string;
    description: string;
    labels: string[];
    diamond: number;
    cover: string;
}) => {
    return fetcher<
        null,
        { url: string; description: string; labels: string[]; diamond: number; cover: string }
    >({
        url: `/couple/v1/creation/image`,
        method: 'POST',
        data
    });
};

interface GetImageInfo {
    id: string;
    url: string;
    description: string;
    labels: creationLabelType[];
    status: 0 | 1 | 2; //'0 審核中 1 已過審 2 未過審'
    reason: string;
    diamond: number;
    view_count: number;
    created_at: string;
}
/** 取得照片詳情 */
export const getImageInfo = (imageId: string) => {
    return fetcher<GetImageInfo, null>({
        url: `/couple/v1/creation/me/image/${imageId}`,
        method: 'GET'
    });
};

interface UpdateImageInfoInput {
    diamond: number;
    description: string;
    labels: string[];
    url: string;
    cover: string;
    imageId: string;
}
/** 更新cp照片 */
export const updateImageInfo = ({ imageId, ...data }: UpdateImageInfoInput) => {
    return fetcher<GetImageInfo, Omit<UpdateImageInfoInput, 'imageId'>>({
        url: `/couple/v1/creation/image/${imageId}`,
        method: 'PUT',
        data: data
    });
};
interface AddVide {
    cover: string;
    url: string;
    description: string;
    labels: string[];
    diamond: number;
    duration: number;
    width: number;
    height: number;
}
/** 建立cp影片 */
export const addVideo = (data: AddVide) => {
    return fetcher<null, AddVide>({
        url: `/couple/v1/creation/video`,
        method: 'POST',
        data
    });
};

interface GetVideoInfo {
    id: string;
    cover: string;
    url: string;
    duration: number;
    width: number;
    height: number;
    diamond: number;
    description: string;
    status: 0 | 1 | 2; //'0 審核中 1 已過審 2 未過審'
    reason: string;
    editable: boolean;
    created_at: string;
    view_count: number;
    unlock_count: number;
    labels: { key: string; name: string }[];
}

/** 取得照片詳情 */
export const getVideoInfo = (videoId: string) => {
    return fetcher<GetVideoInfo, null>({
        url: `/couple/v1/creation/me/video/${videoId}`,
        method: 'GET'
    });
};

interface UpdateVideoInput {
    diamond: number;
    description: string;
    labels: string[];
    url: string;
    cover: string;
    videoId: string;
}

/** 更新cp影片 */
export const updateVideoInfo = ({ videoId, ...data }: UpdateVideoInput) => {
    return fetcher<null, Omit<UpdateVideoInput, 'videoId'>>({
        url: `/couple/v1/creation/video/${videoId}`,
        method: 'PUT',
        data
    });
};

/** 刪除cp照片 */
export const deleteImage = (id: string) => {
    return fetcher<null, string>({
        url: `/couple/v1/creation/image/${id}`,
        method: 'DELETE'
    });
};

/** 刪除cp影片 */
export const deleteVideo = (videoId: string) => {
    return fetcher<null, string>({
        url: `/couple/v1/creation/video/${videoId}`,
        method: 'DELETE'
    });
};

/** 刪除cp迷片預告片 */
export const deleteDramaTrailerVideo = (videoId: string) => {
    return fetcher<null, string>({
        url: `/couple/v1/creation/drama/${videoId}/trailer`,
        method: 'DELETE'
    });
};

/** 刪除cp迷片影片 */
export const deleteDramaVideo = (videoId: string) => {
    return fetcher<null, string>({
        url: `/couple/v1/creation/drama/${videoId}`,
        method: 'DELETE'
    });
};

interface UploadCover {
    url: string;
}

type UploadCoverInput = UploadCover[];

/** 建立cp封面 */
export const createCover = (url: UploadCoverInput) => {
    return fetcher<CoverItem[], UploadCoverInput>({
        url: `/couple/v1/cover`,
        method: 'POST',
        data: url
    });
};

/** 刪除cp封面 */
export const removeCover = (id: string) => {
    return fetcher<null, { id: string }>({
        url: `/couple/v1/cover?id=${id}`,
        method: 'DELETE'
    });
};

/** 更新cp封面 */
export const updateCover = (covers: { id: string; url: string }[]) => {
    return fetcher<CoverItem[], { id: string; url: string }[]>({
        url: `/couple/v1/cover`,
        method: 'PUT',
        data: covers
    });
};

interface CreateDramaInput {
    cover: string;
    title: string;
    description: string;
    labels: string[];
    diamond: number;
}

interface CreateDramaResponse {
    id: string;
    title: string;
    cover: string;
    status: 0 | 1 | 2;
    reason: string;
    editable: boolean;
    description: string;
    labels: string[];
    created_at: string;
    diamond: number;
}

export const createDrama = (data: CreateDramaInput) => {
    return fetcher<CreateDramaResponse, CreateDramaInput>({
        url: `/couple/v1/creation/drama`,
        method: 'POST',
        data
    });
};

interface UpdateDramaInput extends CreateDramaInput {
    id: string;
}

interface UpdateDramaResponse {
    id: string;
    cover: string;
    diamond: number;
    title: string;
    description: string;
    status: 0 | 1 | 2;
    reason: string;
    editable: boolean;
    created_at: string;
    episode_count: number;
}

export const updateDrama = (data: UpdateDramaInput) => {
    return fetcher<UpdateDramaResponse, CreateDramaInput>({
        url: `/couple/v1/creation/drama/${data.id}`,
        method: 'PUT',
        data
    });
};

/** 刪除迷片 */
export const deleteDrama = (dramaId: string) => {
    return fetcher<null, string>({
        url: `/couple/v1/creation/drama/${dramaId}`,
        method: 'DELETE'
    });
};

interface EpisodeSetting {
    editable_episode: number[];
}

/** 拿到可以建立的集數 */
export const getDramaEpisodeSetting = (dramaId: string) => {
    return fetcher<EpisodeSetting, null>({
        url: `/couple/v1/creation/drama/${dramaId}/episode-setting`,
        method: 'GET'
    });
};

interface CreateEpisodeInput {
    id: string;
    cover: string;
    url: string;
    duration: number;
    labels: string[];
    height: number;
    width: number;
    is_public: boolean;
    episode: number;
}

/** 建立影集 */
export const createEpisode = ({ id, ...data }: CreateEpisodeInput) => {
    return fetcher<null, Omit<CreateEpisodeInput, 'id'>>({
        url: `/couple/v1/creation/drama/${id}/episode`,
        method: 'POST',
        data
    });
};

interface UpdateEpisodeInput {
    dramaId: string;
    episodeId: string;
    cover: string;
    url: string;
    duration: number;
    height: number;
    width: number;
    is_public: boolean;
    labels: string[];
}
/** 修正影集 */
export const updateEpisode = ({ dramaId, episodeId, ...data }: UpdateEpisodeInput) => {
    return fetcher<null, Omit<UpdateEpisodeInput, 'dramaId' | 'episodeId'>>({
        url: `/couple/v1/creation/drama/${dramaId}/episode/${episodeId}`,
        method: 'PUT',
        data
    });
};

/** 刪除迷片影集 */
export const removeEpisode = (data: { dramaId: string; episodeId: string }) => {
    return fetcher<null, { dramaId: string; episodeId: string }>({
        url: `/couple/v1/creation/drama/${data.dramaId}/episode/${data.episodeId}`,
        method: 'DELETE'
    });
};

interface CreateDramaTrailerInput {
    id: string;
    cover: string;
    url: string;
    duration: number;
    labels: string[];
    width: number;
    height: number;
}

/** 建立預告片 */
export const createDramaTrailer = ({ id, ...data }: CreateDramaTrailerInput) => {
    return fetcher<null, Omit<CreateDramaTrailerInput, 'id'>>({
        url: `/couple/v1/creation/drama/${id}/trailer`,
        method: 'POST',
        data
    });
};

interface UpdateDramaTrailerInput {
    dramaId: string;
    trailerId: string;
    cover: string;
    url: string;
    duration: number;
    labels: string[];
    width: number;
    height: number;
}
/** 修正預告片 */
export const updateDramaTrailer = ({ dramaId, trailerId, ...data }: UpdateDramaTrailerInput) => {
    return fetcher<null, Omit<UpdateDramaTrailerInput, 'dramaId' | 'trailerId'>>({
        url: `/couple/v1/creation/drama/${dramaId}/trailer/${trailerId}`,
        method: 'PUT',
        data
    });
};

interface GetDramaTrailerDetailInput {
    dramaId: string;
    trailerId: string;
}

interface GetDramaTrailerDetailResponse {
    id: string;
    status: 0 | 1 | 2;
    reason: string;
    editable: boolean;
    cover: string;
    url: string;
    duration: number;
    width: number;
    height: number;
    created_at: string;
    view_count: number;
    unlock_count: number;
    labels: creationLabelType[];
}

/** 刪除預告片 */
export const removeDramaTrailer = (data: { dramaId: string; trailerId: string }) => {
    return fetcher<null, { dramaId: string; trailerId: string }>({
        url: `/couple/v1/creation/drama/${data.dramaId}/trailer/${data.trailerId}`,
        method: 'DELETE'
    });
};

/** 拿到我的單一迷片預告片詳情 */
export const getDramaTrailerDetail = ({ dramaId, trailerId }: GetDramaTrailerDetailInput) => {
    return fetcher<GetDramaTrailerDetailResponse, null>({
        url: `/couple/v1/creation/me/drama/${dramaId}/trailer/${trailerId}`,
        method: 'GET'
    });
};

interface GetDramaEpisodeDetailInput {
    dramaId: string;
    episodeId: string;
}

interface GetDramaEpisodeDetailResponse {
    id: string;
    status: 0 | 1 | 2;
    reason: string;
    editable: boolean;
    cover: string;
    url: string;
    duration: number;
    width: number;
    height: number;
    created_at: string;
    view_count: number;
    unlock_count: number;
    episode: number;
    is_public: boolean;
    labels: creationLabelType[];
}

/** 拿到我的單一迷片影集詳情 */
export const getDramaEpisodeDetail = ({ dramaId, episodeId }: GetDramaEpisodeDetailInput) => {
    return fetcher<GetDramaEpisodeDetailResponse, null>({
        url: `/couple/v1/creation/me/drama/${dramaId}/episode/${episodeId}`,
        method: 'GET'
    });
};

/** 取得創作標籤列表 **/

export const getLabelList = ({ keyword }: { keyword?: string }) => {
    return fetcher<creationLabelType[], { keyword: string }>({
        url: '/couple/v1/creation/label',
        method: 'GET',
        data: {
            keyword: keyword || ''
        }
    });
};

export const putAutoMessage = ({ autoMessage }: { autoMessage: string }) => {
    return fetcher<null, { content: string }>({
        url: '/couple/v1/auto-message/me',
        method: 'PUT',
        data: {
            content: autoMessage
        }
    });
};
