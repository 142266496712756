'use client';

import CpMediaInfoCard from '@/components/cp/cpMediaInfoCard/cpMediaInfoCard';
import { CpPornAlbumTrailerVideo, CpPornAlbumInfo, CpInfoType } from '@/types/cpType';

function VideoInfo({
    userInfo,
    videoInfo,
    albumInfo,
    onClose
}: {
    userInfo: CpInfoType;
    videoInfo: CpPornAlbumTrailerVideo;
    albumInfo: CpPornAlbumInfo;
    onClose?: () => void;
}) {
    return (
        <CpMediaInfoCard
            onClose={onClose}
            userInfo={userInfo}
            mediaData={videoInfo}
            isVideo
            isPorn
            isTrailer
            albumInfo={albumInfo}
        />
    );
}

export default VideoInfo;
