'use client';

import { motion } from 'framer-motion';
import { useEffect } from 'react';

import { useResourceStore } from '@/store/resource';
import { useUserStore } from '@/store/user';

import style from './uploadProgress.module.scss';

function ProgressLine() {
    const progress = useResourceStore.use.progress();

    return (
        <div className={style.progress}>
            <div className={style.text}>上傳進度 {progress}%</div>
            <div className={style.percent}>
                <div className={style.line} style={{ width: `${progress}%` }}></div>
            </div>
        </div>
    );
}

function UploadProgress() {
    const openUploadProgress = useResourceStore.use.openUploadProgress();
    const isCp = useUserStore.use.isCp();

    useEffect(() => {
        if (openUploadProgress) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [openUploadProgress]);

    return (
        <>
            {openUploadProgress && (
                <motion.div
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    className={style.uploadProgress}
                >
                    <div className={style.box}>
                        <div className={style.title}>{isCp ? '經營小心機' : '上傳中'}</div>
                        <div className={style.content}>
                            {isCp
                                ? '每天上傳一則影片，平均可增加20%在首頁的曝光率喔！'
                                : '請稍候，馬上完成！'}
                        </div>
                        <ProgressLine />
                    </div>
                </motion.div>
            )}
        </>
    );
}

export default UploadProgress;
