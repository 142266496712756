import { isMobile } from 'react-device-detect'

function buildCoverFromVideoElement(videoPlayer: HTMLVideoElement) {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  canvas.width = videoPlayer.videoWidth
  canvas.height = videoPlayer.videoHeight
  context!.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height)
  const dataURL = canvas.toDataURL()
  videoPlayer.ontimeupdate = null // clear up

  return dataURL
}

const getVideoDetail = (
  videoPlayer: HTMLVideoElement
): Promise<{ width: number; height: number; duration: number }> => {
  return new Promise((resolve) => {
    videoPlayer.addEventListener('loadedmetadata', function () {
      resolve({ width: videoPlayer.videoWidth, height: videoPlayer.videoHeight, duration: videoPlayer.duration })
    })
  })
}

const getVideoCover = (videoPlayer: HTMLVideoElement): Promise<string> => {
  return new Promise((resolve) => {
    if (isMobile) {
      videoPlayer.addEventListener('seeked', () => {
        resolve(buildCoverFromVideoElement(videoPlayer))
      })

      const checkTimeAndUpdateCover = () => {
        if (videoPlayer.readyState > 1) {
          videoPlayer.currentTime = 0.1
        } else {
          // Keep checking until the video is ready.
          requestAnimationFrame(checkTimeAndUpdateCover)
        }
      }

      // Start checking on the next frame.
      requestAnimationFrame(checkTimeAndUpdateCover)
    } else {
      videoPlayer.oncanplay = () => {
        videoPlayer.play().then(() => {
          videoPlayer.pause()
          resolve(buildCoverFromVideoElement(videoPlayer))
        })
      }
    }
  })
}

// 不上傳封面版本
export const fetchVideoContent = async (file: File) => {
  const videoPlayer = document.createElement('video') as HTMLVideoElement

  videoPlayer.muted = true // 自動播放必要的參數
  videoPlayer.controls = true // 自動播放必要的參數
  videoPlayer.playsInline = true // 避免觸發 mobile 端的全螢幕播放
  videoPlayer.autoplay = true // 重點，自動播放必要的參數
  videoPlayer.src = URL.createObjectURL(file)

  const getVideoDetailPromise = getVideoDetail(videoPlayer)
  const getVideoCoverPromise = getVideoCover(videoPlayer)
  const [detail, cover] = await Promise.all([getVideoDetailPromise, getVideoCoverPromise])

  // clear up
  videoPlayer.src = ''
  URL.revokeObjectURL(videoPlayer.src)

  return { detail, cover }
}
