'use client';
import Slider from '@mui/material/Slider';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Image from 'next/image';
import { useState, useEffect } from 'react';

import BaseButton from '@/components/baseButton/baseButton';

import IconClose from './img/close.svg';
import ImgDiamond from './img/diamond.png';

import style from './diamondDrawer.module.scss';

interface Props {
    isOpen: boolean;
    close: () => void;
    value: number;
    confirm: (value: number) => void;
    isLoading?: boolean;
    lower?: number;
    upper?: number;
}

function DiamondDrawer({ isOpen, close, value, confirm, isLoading, lower, upper }: Props) {
    const [diamond, setDiamond] = useState(value);

    useEffect(() => {
        if (isOpen) setDiamond(value);
    }, [isOpen, value]);

    const submit = () => {
        confirm(diamond);
        close();
    };

    return (
        <SwipeableDrawer
            PaperProps={{
                style: {
                    borderTopLeftRadius: '16px',
                    borderTopRightRadius: '16px'
                }
            }}
            disableSwipeToOpen
            anchor="bottom"
            onClose={close}
            open={isOpen}
            swipeAreaWidth={20}
            transitionDuration={{ enter: 300, exit: 200 }}
            className={style.diamondDrawer}
            hideBackdrop
        >
            <div className={style.container}>
                <IconClose className={style.close} onClick={close} />
                <Image
                    src={ImgDiamond}
                    height="48"
                    width="48"
                    style={{ objectFit: 'cover' }}
                    alt="鑽石"
                />
                <div className={style.diamond}>{diamond.toLocaleString()}</div>
                <div>設定所需鑽石</div>
                <Slider
                    value={diamond}
                    onChange={(_: Event, value: number) => setDiamond(value)}
                    step={100}
                    min={lower ?? 0}
                    max={upper ?? 10000}
                    valueLabelDisplay="auto"
                    color="#525252"
                />
                <BaseButton
                    type={'normal'}
                    className={style.confirm}
                    onClick={submit}
                    loading={isLoading}
                >
                    確定
                </BaseButton>
            </div>
        </SwipeableDrawer>
    );
}

export default DiamondDrawer;
