import { Socket, io } from 'socket.io-client';

import { useChatStore } from '@/store/chat';
import { useDepositStore } from '@/store/deposit';
import { useNotifyStore } from '@/store/notify';
import { MessageResponse, ResMessage, SendMessage } from '@/types/chat';
import { getApiPath } from '@/utils/handleENV';

type MediaExtra = {
    type: string;
    video_id?: string;
    image_id?: string;
    drama_id?: string;
    episode_id?: string;
    trailer_id?: string;
};

let socket: Socket | null = null;

export const initWebsocket = (token: string) => {
    socket = io(getApiPath(), {
        transports: ['websocket'],
        reconnectionDelayMax: 10000,
        auth: {
            token
        }
    });
    socket.on('connect', () => {
        // eslint-disable-next-line -- log
        console.log('sokcet connect!');
    });

    socket.on('post/message/receive', res => {
        useChatStore.getState().setResMessage(res.message);
    });

    socket.on('post/session/read', res => {
        useChatStore.getState().updateReadTime(res);
    });

    socket.on('disconnect', () => {
        // eslint-disable-next-line -- log
        console.log('socket close');
    });
};

export const sendMessage = (message: SendMessage) => {
    const currentChat = useChatStore.getState().currentChat;

    socket?.emit(
        'post/message/create',
        {
            session_id: currentChat.session_id,
            msg: message
        },
        (res: MessageResponse<ResMessage>) => {
            const setResMessage = useChatStore.getState().setResMessage;
            if (res.code === 200) setResMessage(res.data);
            if (res.code === 400) {
                useNotifyStore.getState().setSnackMessage({ message: res.msg as string });
                setTimeout(() => {
                    useDepositStore.getState().setDepositPopup();
                }, 3000);
            }
            if (res.code === 400001) {
                // cp is_active false
                useNotifyStore.getState().setSnackMessage({ message: '小壞壞暫停服務中' }); // TODO: real text;
            }
        }
    );
};

export const sendMessageGroup = (message: SendMessage, group: string[]) => {
    socket?.emit(
        'post/message/batch-create',
        {
            session_ids: group,
            msg: message
        },
        (res: MessageResponse<ResMessage>) => {
            if (res.code === 200) {
                window.location.reload();
            } else {
                useNotifyStore
                    .getState()
                    .setSnackMessage({ message: res.msg || '系統異常，請洽客服人員' });
            }
        }
    );
};

export const sendGift = ({
    giftId,
    sessionId,
    extra
}: {
    giftId: number;
    sessionId: string;
    extra?: MediaExtra;
}) => {
    socket?.emit(
        'post/message/create',
        {
            session_id: sessionId,
            msg: {
                type: 'gift',
                gift_id: giftId,
                extra
            }
        },
        (res: MessageResponse<ResMessage>) => {
            const setResMessage = useChatStore.getState().setResMessage;
            if (res.code === 200) setResMessage(res.data);
            if (res.code === 400) {
                useNotifyStore.getState().setSnackMessage({ message: res.msg as string });
                setTimeout(() => {
                    useDepositStore.getState().setDepositPopup();
                }, 3000);
            }
            if (res.code === 400001) {
                // cp is_active false
                useNotifyStore.getState().setSnackMessage({ message: '小壞壞暫停服務中' }); // TODO: real text;
            }
        }
    );
};

export const getMessageList = (
    sessionId: string,
    isInit: boolean,
    cursor?: string
): Promise<ResMessage[] | false> => {
    return new Promise(resolve => {
        socket?.emit(
            'get/message-list',
            { limit: 20, session_id: sessionId, cursor },
            (res: MessageResponse<ResMessage[]>) => {
                const setChatMapMessageList = useChatStore.getState().setChatMapMessageList;
                if (res.code === 200) {
                    setChatMapMessageList(res.data, isInit);
                    resolve(res.data);
                } else {
                    resolve(false);
                }
            }
        );
    });
};

export const postRead = (sessionId: string) => {
    socket?.emit('post/session/read', { session_id: sessionId });
};

export const visitPofile = (cpId: string) => {
    const cpList = localStorage.getItem('visit');
    let cpArr = [];
    if (cpList) {
        try {
            cpArr = JSON.parse(cpList);
            if (cpArr.length > 200) cpArr = [];
            if (cpArr.includes(cpId)) {
                return;
            }
        } catch (e) {
            // eslint-disable-next-line
            console.log('visit list error:', e);
        }
    }
    cpArr.push(cpId);
    localStorage.setItem('visit', JSON.stringify(cpArr));
    setTimeout(() => {
        socket?.emit('post/event/view_cp', { cp_id: cpId });
    }, 500);
};
