import { fetcher } from '@/lib/fetcher';

export interface WalletInfo {
    user_id: string;
    user_diamond: number;
    cp_diamond: number;
}

/** 拿個人錢包 */
export const getWalletInfo = () => {
    return fetcher<WalletInfo, null>({
        url: `/wallet/v1/me`,
        method: 'GET'
    });
};

export interface WalletHistoryInput {
    limit: number;
    page: number;
}

export interface WalletHistory {
    account: string;
    amount: number;
    created_at: string;
    id: string;
    op_type: number;
}

/** 拿個用戶錢包歷史 */
export const getWalletHistory = (data: WalletHistoryInput) => {
    return fetcher<WalletHistory[], WalletHistoryInput>({
        url: `/wallet/v1/history/user`,
        method: 'GET',
        data
    });
};

interface GetCpWalletSummaryInput {
    start_date: string;
    end_date: string;
}

export interface GetCpWalletSummaryResponse {
    total: number;
    message: number;
    message_image: number;
    message_video: number;
    creation_image: number;
    creation_video: number;
    creation_film: number;
    gift: number;
    voice_call: number;
    admin_recovery: number;
    admin_distribution: number;
}

/** 取得CP交易明細 */
export const getCpWalletSummary = (data: GetCpWalletSummaryInput) => {
    return fetcher<GetCpWalletSummaryResponse, null>({
        url: `/wallet/v1/summary/cp?start_date=${data.start_date}&end_date=${data.end_date}`,
        method: 'GET'
    });
};

interface GetCpWithdrawSummaryInput {
    page: number;
    limit: number;
}

export interface GetCpWithdrawSummaryResponse {
    month: string;
    description: string;
    amount: number;
    pay_time: string;
}

/** 取得CP領取明細 */
export const getCpWithdrawSummary = (data: GetCpWithdrawSummaryInput) => {
    return fetcher<GetCpWithdrawSummaryResponse[], null>({
        url: `/wallet/v1/withdraw/cp?page=${data.page}&limit=${data.limit}`,
        method: 'GET'
    });
};
