export const dataURLtoFile = async (dataURL: string, fileName: string = 'image.png') => {
  const res = await fetch(dataURL)
  const blob = await res.blob()

  const coverFile = new File([blob], fileName, {
    type: blob.type,
    lastModified: new Date().getTime(),
  })

  return coverFile
}
