import style from './chatNotice.module.scss';

function ChatNotice() {
    return (
        <div className={style.placeHolder}>
            <div className={style.chatNotice}>
                注意：請勿發送或散佈性交易或任何違法訊息，壞壞管理員將 24
                小時巡邏，違反規定帳號將會被停用喔 ！
            </div>
        </div>
    );
}

export default ChatNotice;
