'use client';
import Image from 'next/image';
import { useState, useEffect, useRef } from 'react';

import { getChatGiftCategory, getChatGiftList } from '@/api/chat/chat';
import { Slick } from '@/components/slick/slick';
import { sendGift } from '@/lib/websocket';
import { useChatStore } from '@/store/chat';
import { useUserStore } from '@/store/user';
import { ChatInfo, ChatGiftItem } from '@/types/chat';

import IconDiamond from './img/diamond.svg';

import style from './sticker.module.scss';

type MessageContent = {
    sessionId: string;
    giftId: number;
    extra?: {
        type: string;
        video_id?: string;
        image_id?: string;
        drama_id?: string;
        episode_id?: string;
        trailer_id?: string;
    };
};

interface Props {
    isOpen: boolean;
    chatInfo: ChatInfo;
    switchSticker: () => void;
    mode: 'user' | 'cp';
}

function Sticker({ isOpen, chatInfo, switchSticker, mode }: Props) {
    const isCp = useUserStore.use.isCp();

    const [tabList, setTabList] = useState<
        { label: string; status: string; list: ChatGiftItem[] }[]
    >([]);
    const onSlickEnd = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const keeper = useRef('');

    const fetchGetChatGiftList = async (sessionId: string) => {
        if (keeper.current === sessionId) return;
        keeper.current = sessionId;
        const res = await getChatGiftCategory(sessionId);
        if (res?.status !== 200) return; // TODO: Error Handle
        const resList = await Promise.all(
            res.data.map(item => getChatGiftList(sessionId, item.id))
        );
        if (resList.some(item => item.status !== 200)) return; // TODO: Error Handle
        const dataList = [];
        for (let i = 0; i < res.data.length; i++) {
            dataList.push({
                label: res.data[i].name,
                status: String(res.data[i].id),
                list: (resList[i] as { data: ChatGiftItem[] })?.data
            });
        }
        setTabList(dataList);
    };
    useEffect(() => {
        if (!chatInfo?.id) return;
        fetchGetChatGiftList(chatInfo.id);
    }, [chatInfo]);

    const replyData = useChatStore.use.replyData();
    const resetReplyData = useChatStore.use.resetReplyData();
    const send = (id: number) => {
        if (mode === 'cp') return;
        const params: MessageContent = { giftId: id, sessionId: chatInfo.id };
        if (replyData.enable) {
            params.extra = {
                type: 'creation-ref',
                ...replyData
            };

            resetReplyData();
        }
        sendGift(params);
        switchSticker();
    };

    if (!isOpen) return <></>;
    return (
        <div className={style.sticker}>
            <Slick
                autoHeight
                className={style.slick}
                onSlickEnd={onSlickEnd}
                styling="underline"
                tabs={tabList}
            >
                {tabList.map((tab, tabIndex) => {
                    return (
                        <section
                            className={`${style.stickerList} ${isCp && style.disabled}`}
                            key={tabIndex}
                        >
                            {tab.list?.map((sticker, stickerIndex) => {
                                return (
                                    <div
                                        className={style.itemSticker}
                                        key={stickerIndex}
                                        onClick={() => send(sticker.id)}
                                    >
                                        <div className={style.stickerImg}>
                                            <Image
                                                width="56"
                                                height="56"
                                                src={sticker.image}
                                                alt={sticker.name}
                                            />
                                        </div>
                                        <div className={style.name}>{sticker.name}</div>
                                        <div className={style.diamond}>
                                            <IconDiamond />
                                            <span>{sticker.price.toLocaleString()}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </section>
                    );
                })}
            </Slick>
        </div>
    );
}
export default Sticker;
