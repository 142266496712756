import { initStore } from '@/lib/store';
import type { StoreWithSelectors } from '@/lib/store';

interface baseState {
    episodeFile: File | null;
    setEpisodeFile: (params: File | null) => void;
    episodePreview: string | null;
    setEpisodePreview: (params: string | null) => void;
    trailerFile: File | null;
    setTrailerFile: (params: File | null) => void;
    trailerPreview: string | null;
    setTrailerPreview: (params: string | null) => void;
}

let isInit = true;
let useCpCreateStore: StoreWithSelectors<baseState>;

const initialState = (set: (updater: (state: baseState) => Partial<baseState>) => void) => ({
    episodeFile: null,
    setEpisodeFile: (file: File | null) => {
        set(() => ({
            episodeFile: file
        }));
    },
    episodePreview: null,
    setEpisodePreview: (preview: string | null) => {
        set(() => ({
            episodePreview: preview
        }));
    },
    trailerFile: null,
    setTrailerFile: (file: File | null) => {
        set(() => ({
            trailerFile: file
        }));
    },
    trailerPreview: null,
    setTrailerPreview: (preview: string | null) => {
        set(() => ({
            trailerPreview: preview
        }));
    }
});

const createCpCreateStore = () => {
    if (isInit) {
        useCpCreateStore = initStore<baseState>(initialState);
        isInit = false;
    }
};

export { createCpCreateStore, useCpCreateStore };
